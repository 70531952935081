import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { useSelector, useDispatch } from 'react-redux'

import styles from './TopUpBalanceModal.module.scss'

import {
  SandWatch as SandWatchIcon,
  InfoCircle as InfoIcon,
} from '../../../icons'
import TabsList from 'components/elements/tabs/TabsList'
import InfoNotice from 'components/elements/blocks/InfoNotice'
import CustomModal from 'components/elements/modal/CustomModal'
import InputGroup from 'components/elements/forms/InputGroup'
import CustomInput from 'components/elements/inputs/CustomInput'
import CustomButton from 'components/elements/buttons/CustomButton'

import { selectTopUpBalanceProcessing } from 'store/pricing/selectors'
import { selectSubscriptionInfo } from 'store/user/selectors'
import { ITopUpBalanceData } from 'models/pricing'
import { PricingActionsTypes } from 'store/pricing/actions'
import { IUserSubscription } from 'models/user'
import { minimalPaygTopUp } from 'utils/constants'

interface ITopUpBalanceModal {
  isModalShown: boolean
  activeTopUpTabIndex: number
  onActiveTopUpTabIndex: (index: number) => void
  onModalClose: () => void
}

const tabItems = [
  {
    label: 'One-time purchase',
  },
  {
    label: 'Auto top-up',
  },
]

const TopUpBalanceModal: React.FC<ITopUpBalanceModal> = (props) => {
  const {
    isModalShown,
    activeTopUpTabIndex,
    onActiveTopUpTabIndex,
    onModalClose,
  } = props

  const dispatch = useDispatch()

  const topUpBalanceProcessing = useSelector(selectTopUpBalanceProcessing)
  const subscriptionInfo: IUserSubscription = useSelector(
    selectSubscriptionInfo
  )

  const [amount, setAmount] = useState('')
  const [autoAmount, setAutoAmount] = useState('')
  const [threshold, setThreshold] = useState('')
  const [wasStatusChanged, setStatusChange] = useState(false)
  const [isDeactivationNoticeShown, toggleDeactivationNoticeStatus] =
    useState(false)

  const availabilityDate = moment().add(1, 'year').format('DD MMMM YYYY')

  const handleTabChange = (index: number) => onActiveTopUpTabIndex(index)

  const handleCloseModal = () => {
    setAmount('')
    toggleDeactivationNoticeStatus(false)
    setStatusChange(false)

    onModalClose()
  }

  const handleSubmit = () => {
    let topUpData: ITopUpBalanceData

    if (activeTopUpTabIndex === 0) {
      topUpData = {
        amount: +amount,
        one_time_operation: true,
      }
    } else {
      topUpData = {
        amount: +autoAmount,
        one_time_operation: false,
        threshold: threshold.toString(),
      }
    }

    dispatch({
      type: PricingActionsTypes.PRICING_TOP_UP_BALANCE,
      topUpData,
    })
  }

  const handleCancelAutoTopUp = () => {
    dispatch({ type: PricingActionsTypes.PRICING_CANCEL_AUTO_TOP_UP })
  }

  useEffect(() => {
    if (!!subscriptionInfo?.top_up_params) {
      setThreshold(subscriptionInfo.top_up_params.threshold)
      setAutoAmount(subscriptionInfo.top_up_params.amount)
      setStatusChange(true)
    }
  }, [subscriptionInfo?.top_up_params])

  useEffect(() => {
    if (wasStatusChanged && !subscriptionInfo?.top_up_params) {
      toggleDeactivationNoticeStatus(true)
    }
  }, [wasStatusChanged, subscriptionInfo?.top_up_params])

  const isBtnDisabled = () => {
    if (!!amount && +amount < minimalPaygTopUp) {
      return true
    } else if (activeTopUpTabIndex === 0 && !amount) {
      return true
    } else if (activeTopUpTabIndex === 1 && (!setAutoAmount || !threshold)) {
      return true
    } else {
      return false
    }
  }

  const amountInnerNotice = +amount > 0 ? `for $${amount} (+VAT)` : ''

  const renderAutoBtn = () => {
    if (!!subscriptionInfo?.top_up_params) {
      return (
        <CustomButton
          className={styles.inputGroup}
          wide
          color={'outline'}
          loading={topUpBalanceProcessing}
          // disabled={isBtnDisabled()}
          onClick={handleCancelAutoTopUp}
        >
          Deactivate auto top-up
        </CustomButton>
      )
    } else {
      return (
        <CustomButton
          className={styles.inputGroup}
          wide
          loading={topUpBalanceProcessing}
          disabled={isBtnDisabled()}
          onClick={handleSubmit}
        >
          Activate auto top-up
        </CustomButton>
      )
    }
  }

  const renderActiveModalTab = () => {
    if (activeTopUpTabIndex === 0) {
      return (
        <div>
          <InputGroup
            className={styles.inputGroup}
            labelTop={`Top up amount (${minimalPaygTopUp} USD minimum)`}
          >
            <CustomInput
              innerNotice={amountInnerNotice}
              value={amount}
              type={'number'}
              onChange={(e) => setAmount(e.target.value)}
            />
          </InputGroup>

          <CustomButton
            wide
            loading={topUpBalanceProcessing}
            disabled={isBtnDisabled()}
            onClick={handleSubmit}
          >
            Top up
          </CustomButton>
        </div>
      )
    } else {
      return (
        <div>
          <InputGroup
            className={styles.inputGroup}
            labelTop={'Minimum balance threshold'}
          >
            <CustomInput
              innerNotice={'USD'}
              value={threshold}
              type={'number'}
              readOnly={!!subscriptionInfo?.top_up_params}
              onChange={(e) => setThreshold(e.target.value)}
            />
          </InputGroup>

          <InputGroup
            className={styles.inputGroup}
            labelTop={`Amount to add (${minimalPaygTopUp} USD minimum)`}
            childrenDirection={'vertical'}
            bottomNotice={
              !!subscriptionInfo?.top_up_params
                ? 'If you want to edit, please deactivate and set Auto top up again'
                : ''
            }
          >
            <CustomInput
              innerNotice={amountInnerNotice}
              value={autoAmount}
              type={'number'}
              readOnly={!!subscriptionInfo?.top_up_params}
              onChange={(e) => setAutoAmount(e.target.value)}
            />
          </InputGroup>

          {!!subscriptionInfo?.top_up_params && (
            <InfoNotice
              color={'green'}
              icon={<SandWatchIcon />}
              className={styles.autoTopUpNotice}
            >
              Auto top-up activated
            </InfoNotice>
          )}

          {isDeactivationNoticeShown && (
            <InfoNotice
              color={'blue'}
              icon={<InfoIcon />}
              className={styles.autoTopUpNotice}
            >
              Auto top-up deactivated
            </InfoNotice>
          )}

          {renderAutoBtn()}

          <div className={styles.modalBottom}>
            <a
              href="https://helpcenter.soax.com/en/articles/9925418-how-auto-top-up-works"
              target={'_blank'}
              rel={'noopener noreferrer'}
            >
              Learn more about auto top-up
            </a>
          </div>
        </div>
      )
    }
  }

  return (
    <CustomModal
      title={'Top up balance'}
      isModalOpen={isModalShown}
      shouldCloseOnOverlayClick
      showCross
      onModalClose={handleCloseModal}
    >
      <>
        <TabsList
          items={tabItems}
          activeTabIndex={activeTopUpTabIndex}
          size={'l'}
          type={'bordered'}
          color={'blue'}
          className={styles.modalTabs}
          wide
          onChange={handleTabChange}
        />

        <InfoNotice
          color={'gray'}
          icon={<SandWatchIcon />}
          className={styles.modalNotice}
        >
          Any amount you add now will be available for 12 months (
          {availabilityDate}).
        </InfoNotice>

        {renderActiveModalTab()}
      </>
    </CustomModal>
  )
}

export default TopUpBalanceModal
