import React from 'react'
import ReactDOM from 'react-dom/client'
import { Factor } from '@slashid/slashid'
import { SlashIDProvider, ConfigurationProvider } from '@slashid/react'
import * as Sentry from '@sentry/react'

import './index.css'
import App from './app/App'
import reportWebVitals from './reportWebVitals'
import { initAmplitude } from './utils/analytics'

const REACT_APP_SLASH_ID_ORGANIZATION =
  process.env.REACT_APP_SLASH_ID_ORGANIZATION || ''

const REACT_APP_SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN
const REACT_APP_ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT

const factors: Factor[] = [
  {
    method: 'oidc',
    options: {
      provider: 'google', // google, facebook, github...
      client_id:
        '933931600470-mg278re5tdsi0d258iaknip2hl2fndkk.apps.googleusercontent.com',
      ux_mode: 'popup', // or "redirect"
    },
  },
  { method: 'otp_via_email' },
]

initAmplitude()

Sentry.init({
  dsn: REACT_APP_SENTRY_DSN,
  environment: REACT_APP_ENVIRONMENT,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
    // Sentry.httpClientIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <SlashIDProvider
    oid={REACT_APP_SLASH_ID_ORGANIZATION}
    tokenStorage="localStorage"
  >
    <ConfigurationProvider factors={factors}>
      <App />
    </ConfigurationProvider>
  </SlashIDProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
