import React from 'react'

import styles from './Text.module.scss'

export type TextWeight = 'regular' | 'medium' | 'bold'
export type TextSize = 'xxxl' | 'xxl' | 'xl' | 'l' | 'm' | 's'

export type TextProps = {
  style?: CSSStyleSheet
  weight?: TextWeight
  size?: TextSize
  className?: string
  children?: React.ReactNode
}

const Text: React.FC<TextProps> = ({ children, weight, size, className }) => {
  return (
    <span
      className={`
        ${styles.text} 
        ${styles[weight || 'regular']} 
        ${styles[size || 'l']} 
        ${className || ''}
      `}
    >
      {children}
    </span>
  )
}

export default Text
