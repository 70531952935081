import * as React from 'react'
import type { SVGProps } from 'react'
const SvgLanguage = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    fill="none"
    viewBox="0 0 28 28"
    {...props}
  >
    <g fill="currentColor" fillRule="evenodd" clipRule="evenodd" opacity={0.8}>
      <path d="M3.792 14C3.792 8.362 8.362 3.792 14 3.792S24.208 8.362 24.208 14 19.638 24.208 14 24.208 3.792 19.638 3.792 14M14 5.216a8.784 8.784 0 1 0 0 17.568 8.784 8.784 0 0 0 0-17.568" />
      <path d="M14.519 3.983a.71.71 0 0 1 .998.137l-.567.43.567-.43.002.002.002.004.009.011a5 5 0 0 1 .13.183c.084.124.203.304.344.537a16.5 16.5 0 0 1 1.024 2A18.1 18.1 0 0 1 18.51 14c0 2.968-.742 5.427-1.483 7.143-.37.86-.743 1.535-1.024 2a12 12 0 0 1-.474.72l-.009.01-.003.005v.001l-.522-.395.52.396a.712.712 0 0 1-1.134-.86v-.002l.568.431-.568-.43.004-.005.018-.026q.027-.036.08-.114c.071-.103.175-.26.302-.47.252-.416.593-1.034.934-1.825a16.7 16.7 0 0 0 1.366-6.58c0-2.73-.682-4.995-1.366-6.578a15 15 0 0 0-.934-1.826 10 10 0 0 0-.4-.61l-.004-.004a.71.71 0 0 1 .137-.998M13.481 3.983a.71.71 0 0 1 .136.998l-.567-.43.568.43-.004.005-.099.14c-.07.103-.175.26-.3.469a15 15 0 0 0-.935 1.826A16.65 16.65 0 0 0 10.914 14c0 2.73.682 4.995 1.366 6.579.341.791.682 1.409.934 1.826a10 10 0 0 0 .381.583l.012.016.007.01.004.005-.568.43.567-.43h.001a.712.712 0 0 1-1.135.86l.519-.393c-.519.394-.52.394-.52.393v-.002l-.003-.003-.009-.012-.029-.04-.1-.143a12 12 0 0 1-.345-.536 16.5 16.5 0 0 1-1.024-2A18.1 18.1 0 0 1 9.489 14c0-2.968.742-5.427 1.483-7.144.37-.859.742-1.535 1.024-2a12 12 0 0 1 .474-.719l.009-.011.002-.004.001-.001s.001-.001.553.418l-.552-.42a.71.71 0 0 1 .998-.136" />
      <path d="M4.39 17.324c0-.394.318-.713.712-.713h17.796a.712.712 0 1 1 0 1.425H5.102a.71.71 0 0 1-.712-.712M4.39 10.676c0-.393.318-.712.712-.712h17.796a.712.712 0 0 1 0 1.425H5.102a.71.71 0 0 1-.712-.713" />
    </g>
  </svg>
)
export default SvgLanguage
