import React from 'react'

import styles from './ProxyCheckerTable.module.scss'

import { IProxyCheckerResultItem } from 'models/service'

interface IProxyCheckerTableProps {
  items: IProxyCheckerResultItem[] | null
}

const ProxyCheckerTable: React.FC<IProxyCheckerTableProps> = (props) => {
  const { items } = props

  const tableContent = items?.map((item: IProxyCheckerResultItem, index) => (
    <div className={styles.tableRow} key={`proxy_checker_result_item-${index}`}>
      <div className={styles.tableCell}>{index + 1}</div>
      <div className={styles.tableCell}>{item.proxyUrl}</div>
      <div className={styles.tableCell}>{item.resolvedIp || ''}</div>
      <div className={styles.tableCell}>
        {item.success ? 'OK' : item.errorMessage}
      </div>
      <div className={styles.tableCell}>
        {item.ipInfo.region
          ? `${item.ipInfo.region} (${item.ipInfo.country})`
          : ''}
      </div>
      <div className={styles.tableCell}>
        {item.requestMetrics.totalTime
          ? item.requestMetrics.totalTime.toFixed(2) + ' ms'
          : ''}
      </div>
    </div>
  ))

  return (
    <div className={styles.container}>
      <div className={styles.table}>
        <div className={`${styles.tableRow} ${styles.tableHeader}`}>
          <div className={styles.tableCell}>#</div>
          <div className={styles.tableCell}>Proxy string</div>
          <div className={styles.tableCell}>IP</div>
          <div className={styles.tableCell}>Status</div>
          <div className={styles.tableCell}>Location</div>
          <div className={styles.tableCell}>Resp. time</div>
        </div>
        <div className={styles.tableContentWrapper}>
          <div className={styles.tableContentWrapper}>{tableContent}</div>
        </div>
      </div>
    </div>
  )
}

export default ProxyCheckerTable
