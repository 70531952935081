import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Tooltip } from 'react-tooltip'

import styles from './PlansList.module.scss'

import SubscriptionCard from 'components/modules/pricing/SubscriptionCard'
import { InfoCircle as InfoIcon, Present as PresentIcon } from '../../../icons'

import { ISubscriptionPlan, TProxyTypes } from 'models/pricing'
import { popularPlansId, blackFridayPlansId } from 'utils/constants'
import { selectSubscriptionProcessing } from 'store/pricing/selectors'
import {
  selectSubscriptionInfo,
  selectSubscriptionInfoFetching,
} from 'store/user/selectors'

import PaymentMethod1 from 'assets/images/payment-methods/PaymentMethod-1.png'
import PaymentMethod2 from 'assets/images/payment-methods/PaymentMethod-2.png'
import PaymentMethod3 from 'assets/images/payment-methods/PaymentMethod-3.png'
import PaymentMethod4 from 'assets/images/payment-methods/PaymentMethod-4.png'
import PaymentMethod5 from 'assets/images/payment-methods/PaymentMethod-5.png'

const paymentMethods = [
  PaymentMethod1,
  PaymentMethod2,
  PaymentMethod3,
  PaymentMethod4,
  PaymentMethod5,
]

interface IPlansListProps {
  plansList: any[]
  // activePriceType: 'monthly' | 'quarterly' | 'annualy'
  activePriceType: string
  activePriceTypeTabIndex: number
  isEnterpriseActive: boolean
  className?: string
  processingPlanId?: string | null
  onPlanSelect: (plan: ISubscriptionPlan) => void
}

/*const customOfferPlan = {
  id: 'custom',
  name: 'Custom Offer',
  amount: {
    monthly: '5999+',
    quarterly: '5999+',
    annualy: '5999+',
  },
  type: 'general',
  prices: {
    monthly: {
      proxy: {
        wifi: '0.35',
      },
      ecommerce: {
        example: '0.6',
      },
      social: {
        example: '0.6',
      },
      serp: {
        example: '0.6',
      },
    },
  },
  trial_price: 1.99,
}*/

const PlansList: React.FC<IPlansListProps> = (props) => {
  const {
    plansList,
    // isEnterpriseActive,
    activePriceTypeTabIndex,
    activePriceType,
    onPlanSelect,
    className,
    processingPlanId,
  } = props
  const [activePlanType /*, setActivePlanType*/] = useState<TProxyTypes | null>(
    null
  )

  const subscriptionProcessing = useSelector(selectSubscriptionProcessing)
  const userSubscriptionInfo = useSelector(selectSubscriptionInfo)
  const userSubscriptionInfoFetching = useSelector(
    selectSubscriptionInfoFetching
  )

  const checkIsTrialActual = (plans: ISubscriptionPlan) => {
    let isTrialAvailable = false

    for (let i = 0; i < plans.length; i++) {
      if (plans[i].trial_price) {
        isTrialAvailable = true
      }
    }

    return isTrialAvailable
  }

  const isTrialActual = !!plansList ? checkIsTrialActual(plansList) : false

  /*const handleCustomPlanClick = () => {
    window.open(
      'https://forms.soax.com/contact-salesv2?product=xxx&page=xxx&gclid=xxx&device_id=xxx&user_id=xxx&email=xxx',
      '_blank',
      'noopener,noreferrer'
    )
  }*/

  const handlePlanSelect = (plan: ISubscriptionPlan) => {
    onPlanSelect(plan)
  }

  const blackFridayDescription = (
    <>
      Activate this plan <b>from 11.11 to 9.12</b> to get double credits within
      24 hours! Unused bonus credits will expire after one month.
    </>
  )

  const renderPlansItemsList = () => {
    if (!!plansList.length) {
      return plansList.map((plan: ISubscriptionPlan) => (
        <SubscriptionCard
          key={plan.id}
          plan={plan}
          onPlanClick={handlePlanSelect}
          activePriceTypeTabIndex={activePriceTypeTabIndex}
          activePriceType={activePriceType}
          isTrialActual={isTrialActual}
          subscriptionProcessing={subscriptionProcessing}
          promoLabel={popularPlansId?.includes(plan.id) ? 'MOST POPULAR' : null}
          altPromoLabel={
            blackFridayPlansId?.includes(plan.id) ? 'BLACK FRIDAY x2' : null
          }
          altPromoDescription={
            blackFridayPlansId?.includes(plan.id)
              ? blackFridayDescription
              : null
          }
          planProcessing={
            plan.id === processingPlanId || userSubscriptionInfoFetching
          }
          activePlanType={activePlanType}
          userSubscriptionInfo={userSubscriptionInfo}
          className={styles.planItem}
        />
      ))
    } else {
      return null
    }
  }

  /*if (plansFetching || trialAvailabilityChecking) {
    return <Spinner classname={styles.spinnerContainer} />
  }*/

  const paymentsList1 = paymentMethods.map((method, index) => {
    if (index < 3) {
      return (
        <img
          className={styles.paymentItem}
          src={method}
          key={`payment-method__${index}`}
          alt={'Payment method'}
        />
      )
    } else {
      return null
    }
  })

  const paymentsList2 = paymentMethods.map((method, index) => {
    if (index > 2) {
      return (
        <img
          className={styles.paymentItem}
          src={method}
          key={`payment-method__${index}`}
          alt={'Payment method'}
        />
      )
    } else {
      return null
    }
  })

  if (!plansList?.length) return null

  return (
    <section
      className={`
        ${styles.plansSection}
        ${className || ''}
      `}
    >
      <div className={styles.plansWrapper}>
        <div className={styles.plansRow}>
          {renderPlansItemsList()}
          {/*{isEnterpriseActive && (
            <SubscriptionCard
              plan={customOfferPlan}
              onPlanClick={handleCustomPlanClick}
              isCustomPlan={true}
              className={styles.planItem}
            />
          )}*/}
        </div>
      </div>

      <div className={styles.payments}>
        <div className={styles.paymentsBlock}>
          <span className={styles.paymentsTitle}>We accept</span>
          {paymentsList1}
        </div>

        <div className={styles.paymentsBlock}>
          <span className={styles.paymentsTitle}>Contact support for</span>
          {paymentsList2}

          <div
            className={styles.infoWrapper}
            data-tip
            data-tooltip-id={'payment-info'}
          >
            <InfoIcon />
          </div>

          <Tooltip
            id={`payment-info`}
            className={'tooltip_light'}
            place={'bottom'}
          >
            For Bank Transfers and Crypto payment please contact our Customer
            Support via on-site chat (bottom right of the screen).
          </Tooltip>
        </div>
      </div>

      {isTrialActual && (
        <div className={styles.trial}>
          <div className={styles.trialIconWrapper}>
            <PresentIcon />
          </div>

          <div className={styles.trialInfo}>
            <p>
              With the $1.99 you will get 400 MB traffic or 400 Scraper APIs
              requests.
            </p>
            <p>Trials last 3 days and may be extended for Business plan.</p>
          </div>
        </div>
      )}
    </section>
  )
}

export default PlansList
