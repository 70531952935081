import React from 'react'
import { useIntercom } from 'react-use-intercom'

import styles from './MaintenancePage.module.scss'

import CustomButton from 'components/elements/buttons/CustomButton'
import MainHeader from 'components/navigation/MainHeader'
import PromoBlock from '../../components/elements/blocks/PromoBlock'

const MaintenancePage: React.FC = () => {
  const crumbsItems = [
    {
      label: 'Maintenance',
      isActive: true,
    },
  ]

  const { show } = useIntercom()

  const handleRefresh = () => {
    window.location.reload()
  }

  const handleSupport = () => {
    show()
  }

  return (
    <div className={styles.container}>
      <MainHeader crumbsItems={crumbsItems} reducedDisplayMode />

      <PromoBlock
        title={'Scheduled Maintenance Today (16:00–20:00 UTC)'}
        image={`${process.env.PUBLIC_URL}/maintenance.png`}
        className={styles.notice}
      >
        <>
          <p>
            Our SOAX dashboard will be briefly unavailable during this time.
            However, proxies and the scraping API will remain fully operational{' '}
            <img
              src={`${process.env.PUBLIC_URL}/check.png`}
              className={styles.checkIcon}
              alt={'check'}
            />
            .
          </p>
          <p>
            Need assistance?{' '}
            <span onClick={handleSupport} className={styles.supportLink}>
              Reach out via chat
            </span>{' '}
            - we’re here to help!
          </p>
        </>
      </PromoBlock>

      <CustomButton onClick={handleRefresh}>Refresh page</CustomButton>
    </div>
  )
}

export default MaintenancePage
