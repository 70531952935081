export enum ServiceActionsTypes {
  SERVICE_GET_LOCATIONS = 'SERVICE_GET_LOCATIONS',
  SERVICE_GET_LOCATIONS_SUCCESS = 'SERVICE_GET_LOCATIONS_SUCCESS',
  SERVICE_GET_LOCATIONS_FAIL = 'SERVICE_GET_LOCATIONS_FAIL',

  SERVICE_GET_LOCATION_TARGETS = 'SERVICE_GET_LOCATION_TARGETS',
  SERVICE_GET_LOCATION_TARGETS_SUCCESS = 'SERVICE_GET_LOCATION_TARGETS_SUCCESS',
  SERVICE_GET_LOCATION_TARGETS_FAIL = 'SERVICE_GET_LOCATION_TARGETS_FAIL',

  SERVICE_CHECK_PROXY = 'SERVICE_CHECK_PROXY',
  SERVICE_CHECK_PROXY_SUCCESS = 'SERVICE_CHECK_PROXY_SUCCESS',
  SERVICE_CHECK_PROXY_FAIL = 'SERVICE_CHECK_PROXY_FAIL',
}
