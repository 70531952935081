import React from 'react'

import styles from './ProxyCheckerExportOptions.module.scss'

import CustomButton from 'components/elements/buttons/CustomButton'
import CustomInput from 'components/elements/inputs/CustomInput'
import CustomCheckbox from 'components/elements/inputs/CustomCheckbox'
import CopyButton from 'components/elements/buttons/CopyButton'
import { IProxyCheckerResultItem } from 'models/service'

interface IProxyCheckerExportOptionsProps {
  isSelectResponsiveActive: boolean
  responseTimeValue: number
  filteredResultItems: IProxyCheckerResultItem[] | null
  onSelectResponsive: () => void
  onExportTxt: () => void
  onExportCsv: () => void
  onResponseTimeChange: (val: number) => void
  onResponseTimeBlur: (val: number) => void
}

const ProxyCheckerExportOptions: React.FC<IProxyCheckerExportOptionsProps> = (
  props
) => {
  const {
    isSelectResponsiveActive,
    responseTimeValue,
    filteredResultItems,
    onExportTxt,
    onExportCsv,
    onSelectResponsive,
    onResponseTimeChange,
    onResponseTimeBlur,
  } = props

  const handleResponseTimeChange = (e: any) => {
    onResponseTimeChange(e.target.value)
  }

  const handeResponseTimeBLur = (e: any) => {
    onResponseTimeBlur(e.target.value)
  }

  const proxyUrls = !!filteredResultItems
    ? filteredResultItems.map((result) => result.proxyUrl)
    : null

  const copyingText = !!proxyUrls?.length ? proxyUrls.join('\n') : ''

  return (
    <div className={styles.container}>
      <div className={styles.title}>Export options</div>

      <div className={styles.row}>
        <CustomCheckbox
          className={styles.checkbox}
          id={'select_responsive_proxies_option'}
          checked={isSelectResponsiveActive}
          onChange={onSelectResponsive}
        >
          Select proxies with a response time higher than
        </CustomCheckbox>
        <CustomInput
          className={styles.input}
          type={'number'}
          value={responseTimeValue}
          onChange={handleResponseTimeChange}
          onBlur={handeResponseTimeBLur}
        />
        ms
      </div>

      <div className={styles.controls}>
        <CustomButton
          className={styles.btn}
          color={'outline'}
          disabled={!filteredResultItems || filteredResultItems.length < 1}
          onClick={onExportTxt}
        >
          Export .txt
        </CustomButton>

        <CustomButton
          className={styles.btn}
          color={'outline'}
          disabled={!filteredResultItems || filteredResultItems.length < 1}
          onClick={onExportCsv}
        >
          Export .csv
        </CustomButton>

        {/*<CustomButton
          className={styles.btn}
          color={'outline'}
          onClick={onCopyWorking}
        >
          Copy working proxies to clipboard
        </CustomButton>*/}

        <CopyButton
          color={'outline'}
          text={copyingText}
          className={styles.btn}
          disabled={!filteredResultItems || filteredResultItems.length < 1}
        >
          Copy working proxies to clipboard
        </CopyButton>
      </div>
    </div>
  )
}

export default ProxyCheckerExportOptions
