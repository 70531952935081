import * as React from 'react'
import type { SVGProps } from 'react'
const SvgLightning = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    fill="none"
    viewBox="0 0 28 28"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M11.595 2.93a24.2 24.2 0 0 1 5.476 0l1.86.213a.875.875 0 0 1 .724 1.17l-2.57 7.02h2.748a.875.875 0 0 1 .82 1.182l-.223.591a41.7 41.7 0 0 1-6.458 11.37l-.455.57a.875.875 0 0 1-1.56-.546v-8.345H9.834a.875.875 0 0 1-.872-.797l-.143-1.613a46.4 46.4 0 0 1 0-8.198l.143-1.612a.875.875 0 0 1 .773-.792zm5.278 1.74a22.5 22.5 0 0 0-5.08 0l-1.152.13-.08.901a44.6 44.6 0 0 0 0 7.89l.073.814h2.199c.483 0 .875.392.875.875v6.656a40 40 0 0 0 4.856-8.854h-2.731a.875.875 0 0 1-.822-1.176l2.618-7.15z"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgLightning
