import * as React from 'react'
import type { SVGProps } from 'react'
const SvgDiamond = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    fill="none"
    viewBox="0 0 28 28"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M7.624 3.792c-.682 0-1.32.34-1.698.909l-4.188 6.28a1.29 1.29 0 0 0-.08 1.291 38.45 38.45 0 0 0 9.366 11.996l2.043 1.751c.537.46 1.33.46 1.866 0l2.043-1.75a38.45 38.45 0 0 0 9.366-11.997 1.29 1.29 0 0 0-.08-1.29L22.074 4.7a2.04 2.04 0 0 0-1.698-.91zm-.242 1.88a.29.29 0 0 1 .242-.13h2.73L8.12 10.904q-.077.185-.117.38a81 81 0 0 1-2.46-.22l-1.64-.174zm-3.557 6.97a36.7 36.7 0 0 0 8.16 10.144L8.34 13.063a83 83 0 0 1-2.98-.26zm6.424.53L14 23.175l3.751-10.003q-3.752.171-7.502 0m9.412-.11-3.646 9.724a36.7 36.7 0 0 0 8.16-10.144l-1.534.162q-1.489.156-2.98.259m4.437-2.171-1.64.172q-1.23.13-2.461.221a2 2 0 0 0-.117-.38l-2.234-5.362h2.73c.097 0 .188.048.242.13zm-5.908.507a81 81 0 0 1-8.38 0l2.44-5.856h3.5z"
      clipRule="evenodd"
      opacity={0.8}
    />
  </svg>
)
export default SvgDiamond
