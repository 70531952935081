import * as React from 'react'
import type { SVGProps } from 'react'
const SvgRefresh = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    fill="none"
    viewBox="0 0 28 28"
    {...props}
  >
    <path
      fill="currentColor"
      d="M20.364 9.523a.875.875 0 0 0 .568-1.218l-1.937-4.123a.875.875 0 0 0-1.585.002l-.855 1.83a1 1 0 0 0-.094-.03 9.507 9.507 0 1 0 6.767 6.886.875.875 0 0 0-1.698.424 7.757 7.757 0 1 1-5.725-5.672l-.863 1.847a.875.875 0 0 0 1.016 1.216z"
      opacity={0.8}
    />
  </svg>
)
export default SvgRefresh
