import * as React from 'react'
import type { SVGProps } from 'react'
const SvgBag = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    viewBox="0 0 36 36"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M10.875 9.947V8.192c0-1.3.95-2.403 2.236-2.596l1.83-.275a20.6 20.6 0 0 1 6.118 0l1.83.275a2.625 2.625 0 0 1 2.236 2.596v1.755l2.57.207a4.29 4.29 0 0 1 3.891 3.59 40.7 40.7 0 0 1 0 13.011 4.29 4.29 0 0 1-3.89 3.591l-2.808.227c-4.585.37-9.192.37-13.776 0l-2.808-.227a4.29 4.29 0 0 1-3.89-3.59 40.7 40.7 0 0 1 0-13.011 4.29 4.29 0 0 1 3.89-3.59zm4.4-2.4a18.4 18.4 0 0 1 5.45 0l1.83.274c.184.028.32.185.32.37V9.79a86 86 0 0 0-9.75 0V8.192c0-.186.136-.343.32-.371zm-3.982 4.623c4.464-.36 8.95-.36 13.414 0l2.808.227c.936.075 1.7.78 1.85 1.707q.141.867.24 1.737a26.28 26.28 0 0 1-23.21 0q.099-.871.24-1.736a2.04 2.04 0 0 1 1.85-1.708zm-5.1 6.065a28.53 28.53 0 0 0 23.614 0 38.5 38.5 0 0 1-.442 8.16 2.04 2.04 0 0 1-1.85 1.708l-2.808.227c-4.464.36-8.95.36-13.414 0l-2.808-.227a2.04 2.04 0 0 1-1.85-1.707 38.4 38.4 0 0 1-.442-8.16"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgBag
