import React from 'react'
import { useSelector } from 'react-redux'

import styles from './PricingAdvancedOptions.module.scss'

import {
  Card as CardIcon,
  Bag as BagIcon,
  Check as CheckIcon,
} from '../../../icons'
import CustomButton from 'components/elements/buttons/CustomButton'

import { ISubscriptionPlan } from 'models/pricing'
import {
  selectSubscriptionInfo,
  selectSubscriptionInfoFetching,
} from 'store/user/selectors'

interface IPricingAdvancedOptionsProps {
  plan: ISubscriptionPlan
  className?: string
  onPaygClick: (plan: ISubscriptionPlan) => void
}

const PricingAdvancedOptions: React.FC<IPricingAdvancedOptionsProps> = (
  props
) => {
  const { plan, className, onPaygClick } = props

  const userSubscriptionInfo = useSelector(selectSubscriptionInfo)
  const userSubscriptionInfoFetching = useSelector(
    selectSubscriptionInfoFetching
  )

  const activeSubscriptionPlanId =
    userSubscriptionInfo?.status === 'active' ||
    userSubscriptionInfo?.status === 'paused' ||
    //TODO: for this case btnLabel will be 'Complete payment'
    (userSubscriptionInfo?.status === 'trial' &&
      !userSubscriptionInfo?.full_plan_id)
      ? userSubscriptionInfo?.plan_id
      : null

  const handlePaygClick = () => onPaygClick(plan)

  const handleContactClick = () => {
    window.open(
      'https://forms.soax.com/contact-salesv2?product=xxx&page=xxx&gclid=xxx&device_id=xxx&user_id=xxx&email=xxx',
      '_blank',
      'noopener,noreferrer'
    )
  }

  return (
    <div
      className={`
        ${styles.container}
        ${className || ''}
      `}
    >
      <h3 className={styles.title}>Would you like to explore other options?</h3>

      <div className={styles.row}>
        <div className={styles.paygCard}>
          <div className={styles.paygHeader}>
            <div className={styles.paygIconContainer}>
              <CardIcon />
            </div>
            <div className={styles.paygName}>Pay as you go</div>
          </div>

          <div className={styles.paygDescription}>
            Starting at 1GB proxy for <b>$4.00/GB</b> and including all the
            essential features across all the products.
          </div>

          <CustomButton
            color={'outline'}
            onClick={handlePaygClick}
            wide
            loading={userSubscriptionInfoFetching}
            disabled={plan?.id === activeSubscriptionPlanId}
          >
            {plan?.id === activeSubscriptionPlanId
              ? 'Current plan'
              : 'Choose PAYG'}
          </CustomButton>
        </div>

        <div className={styles.enterpriseCard}>
          <div className={styles.enterpriseBlock}>
            <div className={styles.enterpriseHeader}>
              <div className={styles.enterpriseIconContainer}>
                <BagIcon />
              </div>

              <div className={styles.enterpriseName}>Enterprise</div>
            </div>

            <div className={styles.enterpriseDescription}>
              Our custom solutions for enterprises include bulk pricing
              discounts from <b>$1.90/GB</b> up to <b>$0.40/GB</b>, and all the
              support needed to run proxies and scraping APIs at scale.
            </div>
          </div>
          <div className={styles.enterpriseBlock}>
            <div className={styles.enterpriseFeatures}>
              <div className={styles.enterpriseFeature}>
                <div className={styles.enterpriseFeatureIconWrapper}>
                  <CheckIcon />
                </div>
                All Business plan features
              </div>

              <div className={styles.enterpriseFeature}>
                <div className={styles.enterpriseFeatureIconWrapper}>
                  <CheckIcon />
                </div>
                Bulk pricing discount
              </div>

              <div className={styles.enterpriseFeature}>
                <div className={styles.enterpriseFeatureIconWrapper}>
                  <CheckIcon />
                </div>
                Premium SLAs
              </div>

              <div className={styles.enterpriseFeature}>
                <div className={styles.enterpriseFeatureIconWrapper}>
                  <CheckIcon />
                </div>
                Extended free Trial
              </div>
            </div>

            <CustomButton
              wide
              color={'white'}
              className={styles.enterpriseBtn}
              onClick={handleContactClick}
            >
              Contact Sales
            </CustomButton>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PricingAdvancedOptions
