import React, { ReactElement, useCallback, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useSlashID } from '@slashid/react'
import { PersonHandle } from '@slashid/slashid'
import { useIntercom } from 'react-use-intercom'
import { useLocation, useSearchParams, useNavigate } from 'react-router-dom'
import * as Sentry from '@sentry/react'

import { UserActionsTypes } from 'store/user/actions'
import {
  selectTempUserFirstName,
  selectTempUserLastName,
  selectIntercomUserHash,
  selectUserHash,
  selectInitialLoading,
  selectUserFirstName,
  selectUserLastName,
  selectUserEmail,
} from 'store/user/selectors'
import { setAmplitudeUserId } from 'utils/analytics'

import styles from './MainLayout.module.scss'

import NavSidebar, {
  INavSection,
} from 'components/navigation/NavSidebarNew/NavSidebar'
import InvoiceModal from 'components/modules/pricing/InvoiceModal'
import InitialLoadingScreen from 'components/elements/progress/InitialLoadingScreen/InitialLoadingScreen'

export interface IMainLayout {
  navSections?: INavSection[] | null
  isMobileMenuOpen: boolean
  isFullFunctional?: boolean
  topSlot?: ReactElement
  onToggleMobileMenu: () => void
  children: React.ReactNode
}

const MainLayout: React.FC<IMainLayout> = (props) => {
  const {
    navSections,
    isMobileMenuOpen,
    isFullFunctional = true,
    onToggleMobileMenu,
    children,
  } = props

  const currentYear = new Date().getFullYear()

  const { user } = useSlashID()
  const dispatch = useDispatch()
  const { update, show } = useIntercom()
  const { pathname } = useLocation()
  const naviagate = useNavigate()
  // @ts-ignore
  const dataLayer = window.dataLayer

  const enteredUserFirstName = useSelector(selectTempUserFirstName)
  const enteredUserLastName = useSelector(selectTempUserLastName)
  const userHash = useSelector(selectUserHash)
  const intercomUserHash = useSelector(selectIntercomUserHash)
  const initialLoading = useSelector(selectInitialLoading)
  const userFirstName = useSelector(selectUserFirstName)
  const userLastName = useSelector(selectUserLastName)
  const userEmail = useSelector(selectUserEmail)

  const [searchParams, setSearchParams] = useSearchParams()

  const shouldBeSpaced = pathname !== '/pricing'

  useEffect(() => {
    if (searchParams.has('new_dash') && !!userHash) {
      const forceNewDash = searchParams.get('new_dash') === 'true'

      if (forceNewDash) {
        searchParams.delete('new_dash')

        const newParams: { [key: string]: string } = {}

        searchParams.forEach((value: string, key: string) => {
          newParams[key] = value
        })

        setSearchParams(newParams)

        if (pathname === '/sign-up' || pathname === '/sign-in') {
          naviagate({ pathname: '/', search: window.location.search })
        }
      }
    }
  }, [naviagate, pathname, searchParams, setSearchParams, userHash])

  useEffect(() => {
    if (searchParams.has('preset_to_buy') && pathname !== '/pricing') {
      naviagate({ pathname: '/pricing', search: window.location.search })
    }
  }, [naviagate, pathname, searchParams, userHash])

  useEffect(() => {
    if (searchParams.has('action') && !!userHash) {
      let actionParams = searchParams.getAll('action')

      const newParams: { [key: string]: string } = {}

      if (actionParams.indexOf('open_intercom') >= 0) {
        show()

        searchParams.forEach((value: string, key: string) => {
          if (key !== 'action' && value !== 'open_intercom') {
            newParams[key] = value
          } else if (key === 'action' && value !== 'open_intercom') {
            newParams[key] = value
          }
        })
      } else {
        searchParams.forEach((value: string, key: string) => {
          newParams[key] = value
        })
      }

      setSearchParams(newParams)
    }
  }, [searchParams, setSearchParams, show, userHash])

  const addNewAttribute = useCallback(
    async (newAttributes: { [x: string]: string }) => {
      const bucket = user?.getBucket()

      const attrs = await bucket?.get<Record<string, string>>()

      bucket?.set({
        ...attrs,
        ...newAttributes,
      })
    },
    [user]
  )

  const setUserName = useCallback(
    (firstName: string, lastName: string) => {
      dispatch({
        type: UserActionsTypes.USER_SET_FIRST_NAME,
        firstName: firstName,
      })

      dispatch({
        type: UserActionsTypes.USER_SET_LAST_NAME,
        lastName: lastName,
      })
    },
    [dispatch]
  )

  useEffect(() => {
    async function fetchUserAttributes() {
      // getBucket takes in an optional `bucketName | string` argument
      // if not present, it will return the default Read/Write bucket
      const bucket = user?.getBucket()

      // calling bucket.get() with no arguments will return all attributes stored in this bucket
      const attrs = await bucket?.get<Record<string, string>>()

      if (
        !!attrs &&
        (!attrs?.firstName || !attrs?.lastName) &&
        !!enteredUserFirstName &&
        !!enteredUserLastName
      ) {
        await addNewAttribute({
          firstName: enteredUserFirstName,
          lastName: enteredUserLastName,
        })

        setUserName(enteredUserFirstName, enteredUserLastName)

        dispatch({
          type: UserActionsTypes.USER_SET_TEMP_FIRST_NAME,
          tempFirstName: null,
        })
        dispatch({
          type: UserActionsTypes.USER_SET_TEMP_LAST_NAME,
          tempLastName: null,
        })
      } else if (attrs?.firstName && attrs?.lastName) {
        await setUserName(attrs.firstName, attrs.lastName)
      }
    }

    fetchUserAttributes()
  }, [])

  useEffect(() => {
    async function getUserHandles() {
      return user?.getHandles()
    }

    async function fetchUserHandles() {
      let handles: PersonHandle[] | undefined = await getUserHandles()

      if (!!handles) {
        dispatch({
          type: UserActionsTypes.USER_SET_EMAIL,
          email: handles[0].value,
        })
      }
    }

    fetchUserHandles()
  }, [dispatch, user])

  useEffect(() => {
    if (intercomUserHash && userEmail) {
      update({
        name: `${userFirstName} ${userLastName}`,
        email: userEmail,
        userId: user?.ID,
        userHash: intercomUserHash,
      })

      dataLayer.push({
        first_name: userFirstName,
        last_name: userLastName,
        email: userEmail,
        user_id: userHash,
      })

      Sentry.setUser({
        id: user?.ID,
        email: userEmail,
      })
    }
  }, [
    intercomUserHash,
    userHash,
    userEmail,
    update,
    userFirstName,
    userLastName,
    user?.ID,
    dataLayer,
  ])

  useEffect(() => {
    if (userHash) {
      setAmplitudeUserId(userHash)
    }
  }, [userHash])

  if (initialLoading) return <InitialLoadingScreen />

  return (
    <main className={styles.wrapper}>
      <NavSidebar
        navSections={navSections}
        isMobileMenuOpen={isMobileMenuOpen}
        isFullFunctional={isFullFunctional}
        onToggleMobileMenu={onToggleMobileMenu}
      />

      <section className={styles.content}>
        <div className={styles.pageWrapper}>
          <div
            className={`
              ${styles.pageContainer}
              ${shouldBeSpaced ? styles.spaced : ''}
            `}
          >
            <div className={styles.pageContent}>{children}</div>

            <div className={styles.footerWrapper}>
              <div className={styles.footer}>
                <div className={styles.footerCred}>
                  {currentYear} © Soax Ltd. All rights reserved.
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <InvoiceModal />
    </main>
  )
}

export default MainLayout
