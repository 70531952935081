import React from 'react'

import styles from './ProxyCheckerResultBlock.module.scss'
import {
  Lightning as LightningIcon,
  ThumbUp as ThumbIcon,
  Timer as TimerIcon,
} from '../../../../icons'

import { IProxyCheckerResultStat } from 'models/service'

interface IProxyCheckerResultBlockProps {
  data: IProxyCheckerResultStat | null
}

const ProxyCheckerResultBlock: React.FC<IProxyCheckerResultBlockProps> = ({
  data,
}) => {
  const averageLatency = !!data?.averageLatency
    ? data.averageLatency.toFixed(2)
    : 0
  const minLatency = !!data?.fastestLatency ? data.fastestLatency.toFixed(2) : 0
  const maxLatency = !!data?.longestLatency ? data.longestLatency.toFixed(2) : 0

  return (
    <div className={styles.container}>
      <div className={styles.title}>Results</div>

      <div className={styles.items}>
        <div className={styles.blockItem}>
          <div className={styles.blockItemIconWrapper}>
            <ThumbIcon />
          </div>

          <div>
            <div className={styles.blockItemCount}>
              {data?.successfulRequests || 0}
            </div>
            <div className={styles.blockItemLabel}>Successful requests</div>
            <div className={styles.blockItemValue}>
              Errors: {data?.failedRequests || 0}
            </div>
          </div>
        </div>

        <div className={styles.blockItem}>
          <div className={styles.blockItemIconWrapper}>
            <LightningIcon />
          </div>

          <div>
            <div className={styles.blockItemCount}>{data?.uniqueIPs || 0}</div>
            <div className={styles.blockItemLabel}>Unique IPs</div>
            <div className={styles.blockItemValue}>
              Duplicate IPs: {data?.duplicateIPs || 0}
            </div>
          </div>
        </div>

        <div className={styles.blockItem}>
          <div className={styles.blockItemIconWrapper}>
            <TimerIcon />
          </div>

          <div>
            <div className={styles.blockItemCount}>
              {averageLatency}
              {' ms'}
            </div>
            <div className={styles.blockItemLabel}>Average latency</div>
            <div className={styles.blockItemValue}>
              Min-Max: {minLatency} ... {maxLatency}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProxyCheckerResultBlock
