import axios from 'axios'
import { get } from 'utils/request'

export const getLocations = async () => await get(`/api/locations`)

export const getLocationTargets = async (data: any) =>
  await get(`/api/location-targets`, {
    params: data,
  })

export const checkProxyList = async (data: string[]) =>
  await axios
    .post('https://rpg-shared-tools-48aa2f0e2ac8.herokuapp.com/proxytest', {
      proxies: data,
    })
    .then((response) => {
      return response.data
    })
    .catch(function (error) {
      return Promise.reject(error.response)
    })
