import React, { ReactElement, useCallback } from 'react'
import { Tooltip } from 'react-tooltip'

import { ISubscriptionCardProps } from 'models/pricing'

import styles from './SubscriptionCard.module.scss'

import CustomButton from 'components/elements/buttons/CustomButton'
import {
  InfoCircle as InfoIcon,
  Bomb as BombIcon,
  Home as HomeIcon,
  MobilePhone as MobileIcon,
  Server as ServerIcon,
  Apps as AppsIcon,
  Xml as XmlIcon,
} from '../../../icons'

import { getPriceWithoutCents, getNormalizedPrice } from 'utils/functions'
import { ICustomButtonProps } from 'components/elements/buttons/CustomButton/CustomButton'

const products: {
  [key: string]: {
    label: string
    icon: ReactElement
  }
} = {
  wifi: {
    label: 'Residential',
    icon: <HomeIcon />,
  },
  mobile: {
    label: 'Mobile',
    icon: <MobileIcon />,
  },
  isp: {
    label: 'ISP USA',
    icon: <ServerIcon />,
  },
  dc: {
    label: 'Datacenter',
    icon: <AppsIcon />,
  },
  scraping: {
    label: 'Scraper APIs',
    icon: <XmlIcon />,
  },
}

const SubscriptionCard: React.FC<ISubscriptionCardProps> = (props) => {
  const {
    plan,
    className,
    // isTrialActual,
    subscriptionProcessing,
    planProcessing,
    promoLabel,
    altPromoLabel,
    altPromoDescription,
    isCustomPlan,
    onPlanClick,
    activePriceTypeTabIndex,
    activePriceType,
    userSubscriptionInfo,
  } = props

  const isTrialAvailableForPlan =
    !!plan.trial_price && plan.type && plan.type !== 'payg'

  const handlePlanClick = useCallback(() => {
    onPlanClick(plan)
  }, [onPlanClick, plan])

  const activeSubscriptionPlanId =
    userSubscriptionInfo?.status === 'active' ||
    userSubscriptionInfo?.status === 'paused' ||
    //TODO: for this case btnLabel will be 'Complete payment'
    (userSubscriptionInfo?.status === 'trial' &&
      !userSubscriptionInfo?.full_plan_id)
      ? userSubscriptionInfo?.plan_id
      : null

  const renderButton = useCallback(() => {
    let btnLabel: string
    let btnColor: ICustomButtonProps['color'] = 'blue'
    let isPermanentlyDisabled: boolean = false

    if (
      userSubscriptionInfo?.status === 'trial' &&
      plan.id === userSubscriptionInfo?.full_plan_id
    ) {
      btnLabel = 'Activate full plan'
    } else if (plan.id === activeSubscriptionPlanId) {
      btnLabel = 'Current Plan'
      isPermanentlyDisabled = true
    } else if (isCustomPlan) {
      btnColor = 'outline'
      btnLabel = 'Contact Sales'
    } else if (isTrialAvailableForPlan) {
      btnLabel = plan.trial_price
        ? `Try for $${plan.trial_price}`
        : 'Get Free Trial'
    } else {
      btnLabel = 'Subscribe'
    }

    if (!!altPromoLabel) {
      btnColor = 'black'
    }

    return (
      <CustomButton
        className={`
            ${styles.btn}
            ${!!altPromoLabel ? styles.altHighlighted : ''}
          `}
        onClick={handlePlanClick}
        loading={planProcessing}
        disabled={subscriptionProcessing || isPermanentlyDisabled}
        largeRadius={true}
        size={'m'}
        color={btnColor}
      >
        {btnLabel}
      </CustomButton>
    )
  }, [
    userSubscriptionInfo?.status,
    userSubscriptionInfo?.full_plan_id,
    plan.id,
    plan.trial_price,
    activeSubscriptionPlanId,
    isCustomPlan,
    isTrialAvailableForPlan,
    altPromoLabel,
    handlePlanClick,
    planProcessing,
    subscriptionProcessing,
  ])

  const renderHighlight = useCallback(() => {
    if (!!promoLabel) {
      return <div className={styles.highlight}>{promoLabel}</div>
    } else if (!!altPromoLabel) {
      return (
        <>
          <div
            className={styles.highlightAlt}
            data-tip
            data-tooltip-id={`alt_promo_description`}
          >
            {altPromoLabel}&nbsp;
            <InfoIcon />
          </div>
          <Tooltip id={`alt_promo_description`} place={'bottom-end'}>
            {altPromoDescription}
          </Tooltip>
        </>
      )
    } else {
      return null
    }
  }, [altPromoDescription, altPromoLabel, promoLabel])

  const renderMarkers = Object.keys(products).map((value, index) => (
    <div
      className={`
        ${styles.marker}
        ${index === activePriceTypeTabIndex ? styles.active : ''}
      `}
      key={`card_marker_dot-${index}`}
    />
  ))

  /*const renderTrialNotice = () => {
    if (isTrialActual) {
      if (plan.type === 'payg') {
        return <div className={styles.trialNotice}>First payment $15</div>
      } else {
        return (
          <div
            className={styles.trialNotice}
          >{`3-day Trial, then $${planPrice}/mo`}</div>
        )
      }
    } else {
      return null
    }
  }*/

  // @ts-ignore
  const planMonthlyPrice = getPriceWithoutCents(plan?.amount.monthly)

  // const minProxyPrice = plan.prices?.monthly?.proxy
  //   ? getMinimalPrice(Object.values(plan.prices.monthly.proxy))
  //   : null

  // const ecommercePrices = !!plan.prices?.monthly?.ecommerce
  //   ? Object.values(plan.prices.monthly.ecommerce)
  //   : []

  // const serpPrices = !!plan.prices?.monthly?.serp
  //   ? Object.values(plan.prices.monthly.serp)
  //   : []
  //
  // const socialPrices = !!plan.prices?.monthly?.social
  //   ? Object.values(plan.prices.monthly.social)
  //   : []
  //
  // const unblockerPrices = !!plan.prices?.monthly?.unblocker
  //   ? Object.values(plan.prices.monthly.unblocker)
  //   : []

  // const scrapingPricesArray = ecommercePrices.concat(
  //   serpPrices,
  //   socialPrices,
  //   unblockerPrices
  // )

  // const minScrapingPrice = !!scrapingPricesArray?.length
  //   ? // @ts-ignore
  //   getMinimalPrice(scrapingPricesArray)
  //   : null

  // @ts-ignore
  // const minEcommercePrice = getMinimalPrice(ecommercePrices)

  /*const normalizedMinProxyPrice = minProxyPrice
    ? getNormalizedPrice(minProxyPrice.toString())
    : ''
  const normalizedMinScrapingPrice = minScrapingPrice
    ? getNormalizedPrice(minScrapingPrice.toString())
    : ''*/

  /*const normalizedMinEcommercePrice = minEcommercePrice
    ? getNormalizedPrice(minEcommercePrice.toString())
    : ''*/

  const getPlanPrice = () => {
    if (activePriceType && activePriceType !== 'scraping') {
      return !!plan.prices?.monthly?.proxy
        ? getNormalizedPrice(plan.prices?.monthly?.proxy[activePriceType])
        : 'n/a'
    } else if (activePriceType) {
      return getNormalizedPrice(plan.prices?.monthly?.scrapper.unblocker)
    } else {
      return 0
    }

    /*if (plan.type === 'payg') {
      return '15'
    } else if (isCustomPlan) {
      return plan.amount.monthly
    } else if (activePriceType) {
      return getPriceWithoutCents(plan?.amount[activePriceType])
    }*/
  }

  const planPrice = getPlanPrice()

  const getPlanTraffic = () => {
    const traffic = Math.floor(+planMonthlyPrice / +planPrice)

    if (activePriceType && activePriceType !== 'scraping') {
      return ` ≈ ${traffic} GB`
    } else if (activePriceType) {
      return ` ≈ ${traffic} K`
    } else {
      return ''
    }
  }

  const planTraffic = getPlanTraffic()

  return (
    <div
      className={`
        ${styles.container}
        ${className || ''}
      `}
    >
      <div
        className={`
          ${styles.content}
          ${!!promoLabel ? styles.highlighted : ''}
          ${!!altPromoLabel ? styles.highlightedAlt : ''}
        `}
      >
        {renderHighlight()}

        <div className={styles.name}>
          <div className={!!promoLabel ? styles.highlighted : ''}>
            {plan.name}
          </div>

          {!!altPromoLabel && (
            <div className={styles.bombWrapper}>
              <BombIcon />
            </div>
          )}
        </div>

        <div className={styles.price}>
          <div className={styles.priceMain}>${planPrice}</div>

          <div className={styles.priceLabel}>
            {/*{plan.type === 'payg' ? (
              <>
                <p>starting</p>
                <p>from</p>
              </>
            ) : (
              <>/mo.</>
            )}*/}

            {activePriceType === 'scraping' ? <>/ 1K req.</> : <>/ GB</>}
          </div>
        </div>

        <div className={styles.trafficBlock}>
          <div className={styles.activeProductIconWrapper}>
            {activePriceType ? products[activePriceType].icon : null}
          </div>

          {activePriceType ? products[activePriceType].label : null}

          {planTraffic}
        </div>

        <div className={styles.markers}>{renderMarkers}</div>

        <div className={styles.monthlyPrice}>
          <b>${planMonthlyPrice}</b> billed monthly
        </div>

        {/*<div className={styles.info}>
          <div className={styles.infoBlock}>
            <div>APIs</div>

            <div>from ${normalizedMinScrapingPrice}</div>
          </div>

          <div className={styles.infoBlock}>
            <div>Proxies</div>

            <div>from ${normalizedMinProxyPrice}</div>
          </div>
        </div>*/}

        <div className={styles.additionalInfo}>{renderButton()}</div>

        {/*{renderTrialNotice()}*/}
      </div>
    </div>
  )
}

export default SubscriptionCard
