import * as React from 'react'
import type { SVGProps } from 'react'
const SvgBinocular = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={24}
    fill="none"
    viewBox="0 0 25 24"
    {...props}
  >
    <path
      fill="#D95A16"
      fillRule="evenodd"
      d="m2.123 12.341 2.273-6.436a1 1 0 0 1 .04-.093c.38-.738 1.062-1.303 1.79-1.68.732-.379 1.583-.604 2.385-.604 1.148 0 2.29.46 3.147 1.192.275.234.527.502.742.798.215-.296.467-.564.742-.798.857-.731 1.999-1.192 3.147-1.192.802 0 1.653.225 2.386.604.727.377 1.409.942 1.789 1.68q.023.045.04.093l2.273 6.436a5.194 5.194 0 1 1-9.962 2.686h-.83A5.195 5.195 0 0 1 1.75 14.28M4.682 9.6l1.11-3.142c.201-.363.594-.72 1.123-.994.542-.28 1.158-.436 1.696-.436.761 0 1.564.313 2.174.833.608.519.965 1.189.965 1.874v4.567a5.196 5.196 0 0 0-7.068-2.701m5.882 3.933q.074.361.075.745a3.694 3.694 0 1 1-.075-.745m2.686-1.23c.194-.473.456-.91.774-1.3a5.18 5.18 0 0 1 4.1-1.92 5.2 5.2 0 0 1 2.194.518l-1.11-3.143c-.201-.363-.594-.72-1.122-.994-.543-.28-1.16-.436-1.697-.436-.761 0-1.564.313-2.174.833-.608.519-.965 1.189-.965 1.874zm1.111 1.975a3.694 3.694 0 0 0 7.389.002v-.002a4 4 0 0 0-.005-.202 3.696 3.696 0 0 0-3.69-3.493 3.69 3.69 0 0 0-3.694 3.695"
      clipRule="evenodd"
    />
    <path fill="#D95A16" d="M2.123 12.341a5.2 5.2 0 0 0-.373 1.937" />
  </svg>
)
export default SvgBinocular
