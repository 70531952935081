import React, { useEffect, useState, useCallback, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { useSearchParams } from 'react-router-dom'

import styles from './PricingContent.module.scss'

/*import {
  Timer as TimerIcon,
  Present as PresentIcon,
  CheckedBox as CheckIcon,
} from 'components/icons'*/

import Text from 'components/elements/typography/Text'
import TabsList from 'components/elements/tabs/TabsList'
// import CustomToggle from 'components/elements/inputs/CustomToggle'
// import PromoBlock from 'components/elements/blocks/PromoBlock'
import PlansList from 'components/modules/pricing/PlansList/PlansList'
import FeaturesBlock from 'components/modules/pricing/FeaturesBlock'
import PlansComparisonTable from 'components/modules/pricing/PlansComparisonTable'
import Loader from 'components/elements/progress/Loader'
import BillingFormModal from '../BillingFormModal'
import PricingAdvancedOptions from '../PricingAdvancedOptions'

import { logAmplitudeEvent } from 'utils/analytics'

import BestStarterReview from 'assets/images/reviews/best_starter_review.png'
import TopPerformerReview from 'assets/images/reviews/top_performer_review.png'
import HighPerformerReview from 'assets/images/reviews/high_performer_review.png'
import LeaderReview from 'assets/images/reviews/leader_review.png'
import BestRelationshipReview from 'assets/images/reviews/best_relationship_review.png'
import EasiestToDoReview from 'assets/images/reviews/easiest_to_do_review.png'
import BestValueReview from 'assets/images/reviews/best_value_review.png'
import GetAppReview from 'assets/images/reviews/get_app_review.png'
import CapterraReview2 from 'assets/images/reviews/capterra_review2.png'

import {
  selectPlansList,
  selectPlansFetching,
  /*selectInvoiceUrl,*/
  /*selectBillingModalVisibility,
  selectInvoiceModalStatus,*/
} from 'store/pricing/selectors'
import { IBillingInfoData, ISubscriptionPlan } from 'models/pricing'
import { PricingActionsTypes } from 'store/pricing/actions'
import FullActivationModal from '../FullActivationModal'
import { IUserSubscription } from '../../../../models/user'
import { selectSubscriptionInfo } from '../../../../store/user/selectors'
import CustomButton from '../../../elements/buttons/CustomButton'

/*const trialPromoItems = [
  {
    label: '3 days',
    icon: <TimerIcon />,
  },
  {
    label: '$5 on your balance',
    icon: <PresentIcon />,
  },
  {
    label: 'All products',
    icon: <CheckIcon />,
  },
]*/

/*const priceTypesTabItems = [
  {
    label: 'Monthly',
    value: 'monthly',
  },
  {
    label: 'Quarterly',
    value: 'quarterly',
  },
  {
    label: 'Annually',
    value: 'annualy',
    note: 'save 30%',
  },
]*/

const priceTypesTabItems = [
  {
    label: 'Residential proxies',
    value: 'wifi',
  },
  {
    label: 'Mobile proxies',
    value: 'mobile',
  },
  {
    label: 'ISP USA proxies',
    value: 'isp',
  },
  {
    label: 'Datacenter proxies',
    value: 'dc',
  },
  {
    label: 'Scraper APIs',
    value: 'scraping',
  },
]

const PricingContent: React.FC = () => {
  const dispatch = useDispatch()

  const [activePriceTypeTabIndex, setActivePriceTypeTabIndex] = useState(0)
  const [isEnterpriseActive /*, toggleEnterprise*/] = useState(false)
  /*const [processingPlanId, setProcessingPlanId] = useState<string | null>(null)
  const [selectetdPlanId, setSelectedPlanId] = useState<string | null>(null)*/
  const [selectedPlan, setSelectedPlan] = useState<ISubscriptionPlan | null>(
    null
  )
  const [isFullActivationModalShown, toggleFullActivationModal] =
    useState(false)

  const [searchParams, setSearchParams] = useSearchParams()

  const plansList = useSelector(selectPlansList)
  const plansFetching = useSelector(selectPlansFetching)
  const subscriptionInfo: IUserSubscription = useSelector(
    selectSubscriptionInfo
  )
  // const invoiceUrl = useSelector(selectInvoiceUrl)
  // const isBillingModalShown = useSelector(selectBillingModalVisibility)
  // const isInvoiceModalShown = useSelector(selectInvoiceModalStatus)

  const reviews = [
    BestStarterReview,
    TopPerformerReview,
    HighPerformerReview,
    LeaderReview,
    BestRelationshipReview,
    EasiestToDoReview,
    BestValueReview,
    GetAppReview,
    CapterraReview2,
  ]

  const tableRef = useRef<HTMLDivElement | null>(null)

  const handleScroll = () =>
    tableRef.current?.scrollIntoView({
      behavior: 'smooth',
    })

  const handleBillingModalClose = () => {
    /*setSelectedPlanId(null)
    setProcessingPlanId(null)*/

    setSelectedPlan(null)
    dispatch({ type: PricingActionsTypes.PRICING_HIDE_BILLING_FORM })
  }

  const handleTabChange = (index: number) => setActivePriceTypeTabIndex(index)

  // const handleEnterpriseToggle = () => toggleEnterprise(!isEnterpriseActive)

  const getActualPlans = () => {
    if (!plansList) return null

    return plansList.filter((plan: ISubscriptionPlan) => plan.type !== 'payg')

    /*if (isEnterpriseActive) {
      return plansList.filter(
        (product: ISubscriptionPlan) => product.type === 'enterprise'
      )
    } else {
      return plansList.filter(
        (product: ISubscriptionPlan) => product.type !== 'enterprise'
      )
    }*/
  }

  /*useEffect(() => {
    if (isBillingModalShown) {
      setProcessingPlanId(null)
    }
  }, [isBillingModalShown])

  useEffect(() => {
    if (isInvoiceModalShown) {
      setProcessingPlanId(null)
      setSelectedPlanId(null)
    }
  }, [isInvoiceModalShown])*/

  const actualPlans = getActualPlans()

  const activePriceType = priceTypesTabItems[activePriceTypeTabIndex].value

  const handleSubscribe = useCallback(
    (plan: ISubscriptionPlan) => {
      if (
        subscriptionInfo?.status === 'trial' &&
        plan.id === subscriptionInfo?.full_plan_id
      ) {
        handleActivateFullPlan()
      } else {
        setSelectedPlan(plan)

        if (plan.type === 'payg') {
          logAmplitudeEvent('click subscribe payg')
        } else if (!!plan.trial_price) {
          logAmplitudeEvent('click start subscription trial')
        }

        dispatch({
          type: PricingActionsTypes.PRICING_SHOW_BILLING_FORM,
        })
      }
    },
    [dispatch, subscriptionInfo?.full_plan_id, subscriptionInfo?.status]
  )

  useEffect(() => {
    if (searchParams.has('preset_to_buy') && !!plansList) {
      const planId = searchParams.get('preset_to_buy')

      const plan = plansList.filter(
        (plan: ISubscriptionPlan) => plan.id === planId
      )[0]

      if (!!plan) {
        handleSubscribe(plan)

        searchParams.delete('preset_to_buy')

        const newParams: { [key: string]: string } = {}

        searchParams.forEach((value: string, key: string) => {
          newParams[key] = value
        })

        setSearchParams(newParams)
      }
    }
  }, [handleSubscribe, plansList, searchParams, setSearchParams])

  const handleActivateFullPlan = () => {
    logAmplitudeEvent('click Activate-full started')

    toggleFullActivationModal(true)
  }

  const handleCloseFullActivationModal = () => {
    toggleFullActivationModal(false)
  }

  const handleSubmitBillingForm = (data: IBillingInfoData) => {
    const subscriptionData = {
      ...data,
    }

    dispatch({
      type: PricingActionsTypes.PRICING_GET_INVOICE,
      subscriptionData,
    })
  }

  const paygPlan = plansList?.filter(
    (plan: ISubscriptionPlan) => plan.type === 'payg'
  )[0]

  const reviewsList = reviews.map((item, index) => {
    return (
      <img
        className={styles.reviewItem}
        src={item}
        key={`review-item__${index}`}
        alt={'review'}
      />
    )
  })

  if (plansFetching) {
    return (
      <div className={styles.loaderContainer}>
        <Loader />
      </div>
    )
  }

  return (
    <div className={styles.wrapper}>
      <Text className={styles.title} size={'xxxl'} weight={'bold'}>
        Choose your subscription plan
      </Text>

      <div className={styles.controls}>
        <div className={styles.controlsLabel}>Filter price for</div>

        <TabsList
          className={styles.tabs}
          itemClassName={styles.tabsItem}
          items={priceTypesTabItems}
          activeTabIndex={activePriceTypeTabIndex}
          size={'l'}
          color={'blue'}
          type={'bordered'}
          onChange={handleTabChange}
        />

        {/*<CustomToggle
          id={'enterprise-checkbox'}
          labelLeft={'Enterprise'}
          checked={isEnterpriseActive}
          onChange={handleEnterpriseToggle}
        />*/}
      </div>

      <PlansList
        className={styles.plansList}
        plansList={actualPlans}
        activePriceTypeTabIndex={activePriceTypeTabIndex}
        activePriceType={activePriceType}
        onPlanSelect={handleSubscribe}
        isEnterpriseActive={isEnterpriseActive}
        // processingPlanId={processingPlanId}
      />

      <div className={styles.anchorLinkBlock}>
        <CustomButton
          color={'ghost'}
          className={styles.anchorLink}
          onClick={handleScroll}
        >
          Detailed plans comparison
        </CustomButton>
      </div>

      <div className={styles.paddingBlock}>
        <FeaturesBlock
          activePlanType={activePriceType}
          className={styles.featuresBlock}
        />

        <PricingAdvancedOptions
          plan={paygPlan}
          onPaygClick={handleSubscribe}
          className={styles.advancedOptions}
        />
      </div>

      {/*{isTrialAvailable && (
        <PromoBlock
          title={'Try before subscribe'}
          image={`${process.env.PUBLIC_URL}/ball.png`}
          items={trialPromoItems}
          className={styles.promoBlock}
        />
      )}*/}

      <div ref={tableRef} />

      <PlansComparisonTable
        plans={plansList}
        activePriceType={activePriceType}
        // processingPlanId={processingPlanId}
        onPlanSelect={handleSubscribe}
        onOuterTabChange={handleTabChange}
      />

      <div className={styles.reviewList}>{reviewsList}</div>

      <BillingFormModal
        selectedPlan={selectedPlan}
        onModalClose={handleBillingModalClose}
        onSubmitBillingForm={handleSubmitBillingForm}
      />

      {!!subscriptionInfo?.full_plan_id && (
        <FullActivationModal
          isModalShown={isFullActivationModalShown}
          fullPlanPrice={subscriptionInfo.full_plan_price}
          fullPlanId={subscriptionInfo.full_plan_id}
          onModalClose={handleCloseFullActivationModal}
        />
      )}
    </div>
  )
}

export default PricingContent
