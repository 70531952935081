import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { saveAs } from 'file-saver'

import styles from './ProxyChecker.module.scss'

import { CaretDown as CaretIcon } from '../../../icons'
import ProxyCheckerResultBlock from './ProxyCheckerResultBlock'
import ProxyCheckerExportOptions from './ProxyCheckerExportOptions'
import ProxyCheckerTable from './ProxyCheckerTable'

import {
  selectProxyCheckerResult,
  selectProxyCheckerActivationStatus,
} from 'store/service/selectors'
import { IProxyCheckerResultItem } from 'models/service'

const ProxyChecker: React.FC = () => {
  const [isBlockActive, toggleBlock] = useState(false)
  const [isSelectResponsiveActive, toggleSelectResponsive] = useState(false)
  const [responseTimeValue, setResponseTimeValue] = useState(1000)

  const checkerResult = useSelector(selectProxyCheckerResult)
  const wasProxyCheckerActivated = useSelector(
    selectProxyCheckerActivationStatus
  )

  useEffect(() => {
    if (wasProxyCheckerActivated) {
      toggleBlock(true)
    }
  }, [wasProxyCheckerActivated])

  const handleToggle = () => toggleBlock(!isBlockActive)

  const handleSelectResponsive = () => {
    toggleSelectResponsive(!isSelectResponsiveActive)
  }

  const getFilteredResults = (result: IProxyCheckerResultItem[]) => {
    if (!!result) {
      let filteredResults = result.filter(
        (result: IProxyCheckerResultItem) => result.success
      )

      if (isSelectResponsiveActive && !!responseTimeValue) {
        const excludeTimeNumber = parseFloat(responseTimeValue.toString())
        filteredResults = filteredResults.filter(
          (result: IProxyCheckerResultItem) =>
            result.requestMetrics.totalTime <= excludeTimeNumber
        )
      }

      return filteredResults
    } else {
      return null
    }
  }

  const filteredResult = getFilteredResults(checkerResult?.results)

  const handleExportTxt = () => {
    const filteredResults = getFilteredResults(checkerResult.results)

    if (!!filteredResults) {
      const proxyUrls = filteredResults?.map((result) => result.proxyUrl)

      const blob = new Blob([proxyUrls.join('\n')], {
        type: 'text/plain;charset=utf-8',
      })

      saveAs(blob, 'checked_proxy_list.txt')
    }
  }

  const handleExportCsv = () => {
    if (!!filteredResult) {
      let csvContent = 'ProxyURL,IP,Status,Location,ResponseTime\n'

      filteredResult.forEach((result) => {
        const row = [
          result.proxyUrl,
          result.resolvedIp || '',
          result.success ? 'OK' : result.errorMessage,
          result.ipInfo.region
            ? `${result.ipInfo.region} (${result.ipInfo.country})`
            : '',
          result.requestMetrics.totalTime
            ? result.requestMetrics.totalTime.toFixed(2) + ' ms'
            : '',
        ].join(',')

        csvContent += row + '\n'
      })

      const blob = new Blob([csvContent], { type: 'text/csv' })

      saveAs(blob, 'checked_proxy_list.csv')
    } else {
      return null
    }
  }

  const handleCopyWorking = () => {}

  const handleResponseTimeChange = (val: number) => {
    setResponseTimeValue(val)
  }

  const handleResponseTimeBlur = (val: number) => {
    if (val < 1) {
      setResponseTimeValue(1)
    }
  }

  return (
    <div className={styles.container}>
      <button
        className={`
            ${styles.toggle}
            ${isBlockActive ? styles.active : ''}
          `}
        onClick={handleToggle}
      >
        <>
          <span className={styles.title}>Proxy checker results</span>
          <div className={styles.toggleIconWrapper}>
            <CaretIcon />
          </div>
        </>
      </button>

      <div
        className={`
            ${styles.content}
            ${isBlockActive ? styles.active : ''}
          `}
      >
        {!checkerResult && (
          <div className={styles.emptyNotice}>
            Please, set proxy list above and click 'Check list with proxy
            checker"
          </div>
        )}

        <ProxyCheckerResultBlock data={checkerResult?.statistics || null} />

        <ProxyCheckerExportOptions
          isSelectResponsiveActive={isSelectResponsiveActive}
          responseTimeValue={responseTimeValue}
          filteredResultItems={filteredResult}
          onSelectResponsive={handleSelectResponsive}
          onExportTxt={handleExportTxt}
          onExportCsv={handleExportCsv}
          onResponseTimeChange={handleResponseTimeChange}
          onResponseTimeBlur={handleResponseTimeBlur}
        />

        {!!checkerResult && (
          <ProxyCheckerTable items={checkerResult?.results || null} />
        )}

        {!!checkerResult && (
          <ProxyCheckerExportOptions
            isSelectResponsiveActive={isSelectResponsiveActive}
            responseTimeValue={responseTimeValue}
            filteredResultItems={filteredResult}
            onSelectResponsive={handleSelectResponsive}
            onExportTxt={handleExportTxt}
            onExportCsv={handleExportCsv}
            onResponseTimeChange={handleResponseTimeChange}
            onResponseTimeBlur={handleResponseTimeBlur}
          />
        )}
      </div>
    </div>
  )
}

export default ProxyChecker
