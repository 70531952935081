import * as React from 'react'
import type { SVGProps } from 'react'
const SvgLink = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    fill="none"
    viewBox="0 0 28 28"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M18.498 3.334a5.6 5.6 0 0 0-3.967 1.606l-.008.008-1.68 1.696a.75.75 0 1 0 1.066 1.055l1.676-1.692a4.1 4.1 0 0 1 2.9-1.173c1.08.01 2.116.449 2.884 1.228a4.25 4.25 0 0 1 1.214 2.944 4.25 4.25 0 0 1-1.16 2.96l-2.925 2.971c-.42.427-.925.756-1.479.966a4.07 4.07 0 0 1-3.42-.25 4.2 4.2 0 0 1-1.328-1.17.75.75 0 1 0-1.208.89 5.66 5.66 0 0 0 1.81 1.592 5.58 5.58 0 0 0 4.677.34 5.6 5.6 0 0 0 2.016-1.315l2.93-2.976.01-.01a5.75 5.75 0 0 0 1.577-4.01 5.75 5.75 0 0 0-1.646-3.984 5.6 5.6 0 0 0-3.939-1.676m-5.672 7.014a5.6 5.6 0 0 0-2.376.347 5.6 5.6 0 0 0-2.016 1.315l-2.93 2.976-.01.01a5.75 5.75 0 0 0-1.577 4.01 5.75 5.75 0 0 0 1.646 3.984 5.6 5.6 0 0 0 3.939 1.677 5.6 5.6 0 0 0 3.967-1.607l.01-.01 1.67-1.695a.75.75 0 0 0-1.07-1.053l-1.665 1.692a4.1 4.1 0 0 1-2.899 1.172 4.1 4.1 0 0 1-2.883-1.228 4.25 4.25 0 0 1-1.215-2.944 4.25 4.25 0 0 1 1.16-2.96l2.926-2.971c.42-.427.924-.756 1.478-.966a4.07 4.07 0 0 1 3.42.25c.519.288.973.687 1.329 1.17a.75.75 0 1 0 1.207-.89 5.7 5.7 0 0 0-1.81-1.592 5.6 5.6 0 0 0-2.301-.687"
      clipRule="evenodd"
      opacity={0.8}
    />
  </svg>
)
export default SvgLink
