import React from 'react'
import { useIntercom } from 'react-use-intercom'

import styles from './MaintenanceBlock.module.scss'

const MaintenanceBlock: React.FC = () => {
  const { show } = useIntercom()

  const handleSupport = () => {
    show()
  }

  return (
    <div className={styles.maintenance}>
      <div
        className={styles.maintenanceImg}
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/maintenance.png)`,
        }}
      />
      <h1 className={styles.maintenanceTitle}>
        Scheduled Maintenance Today (16:00–20:00 UTC)
      </h1>

      <div className={styles.maintenanceDesc}>
        <p>
          Our SOAX dashboard will be briefly unavailable during this time.
          However, proxies and the scraping API will remain fully operational{' '}
          <img
            src={`${process.env.PUBLIC_URL}/check.png`}
            className={styles.checkIcon}
            alt={'check'}
          />
          .
        </p>
        <p>
          Need assistance?{' '}
          <span onClick={handleSupport} className={styles.supportLink}>
            Reach out via chat
          </span>{' '}
          - we’re here to help!
        </p>
      </div>
    </div>
  )
}

export default MaintenanceBlock
