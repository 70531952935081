import * as React from 'react'
import type { SVGProps } from 'react'
const SvgEdit = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M17.879 4.513a.625.625 0 0 1-.08.786l-7.66 7.66a.6.6 0 0 1-.284.163l-3.19.833a.625.625 0 0 1-.763-.762l.833-3.19a.65.65 0 0 1 .134-.26l7.689-7.685a.625.625 0 0 1 .884 0l2.357 2.357q.045.045.08.098m-1.406.344L15 3.384l-7.098 7.098-.521 1.994 1.994-.52z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      d="M16.368 14.3c.228-1.948.3-3.91.217-5.866a.34.34 0 0 1 .098-.251l.82-.82c.1-.1.272-.036.282.106.154 2.324.095 4.66-.176 6.976a3.66 3.66 0 0 1-3.227 3.194 39.8 39.8 0 0 1-8.764 0 3.66 3.66 0 0 1-3.227-3.193 38.3 38.3 0 0 1 0-8.891A3.66 3.66 0 0 1 5.618 2.36a40 40 0 0 1 6.657-.178.165.165 0 0 1 .107.283l-.827.827a.34.34 0 0 1-.248.098 38.5 38.5 0 0 0-5.55.212A2.41 2.41 0 0 0 3.632 5.7a37 37 0 0 0 0 8.6 2.41 2.41 0 0 0 2.125 2.097c2.797.312 5.69.312 8.486 0a2.41 2.41 0 0 0 2.125-2.097"
    />
  </svg>
)
export default SvgEdit
