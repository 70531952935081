import { put, takeLatest, call, all, delay } from 'redux-saga/effects'
import { Action } from 'redux'

import * as error from 'utils/error-handler'
import { PricingActionsTypes } from './actions'
import * as api from './api'
import { getUserBalance, getUserSubscription } from '../user/api'

import { IPricingAction } from 'models/pricing'
import { UserActionsTypes } from '../user/actions'

const getPlans = function* () {
  try {
    const { data } = yield call(api.getPlansList)

    const plansList = data?.sort((planA: any, planB: any) => {
      return +planA.amount.monthly - +planB.amount.monthly
    })

    yield put({
      type: PricingActionsTypes.PRICING_GET_PLANS_SUCCESS,
      plans: plansList,
    })
  } catch (e) {
    const handledAction: Action = yield error.handle(
      { type: PricingActionsTypes.PRICING_GET_PLANS_FAIL },
      e
    )
    yield put(handledAction)
  }
}

const getProductPrice = function* (action: IPricingAction) {
  try {
    const { data } = yield call(api.getProductPrice, {
      product_id_orb: action.productIdOrb,
    })

    yield put({
      type: PricingActionsTypes.PRICING_GET_PRODUCT_PRICE_SUCCESS,
      productPrice: data.price,
    })
  } catch (e) {
    const handledAction: Action = yield error.handle(
      { type: PricingActionsTypes.PRICING_GET_PRODUCT_PRICE_FAIL },
      e
    )
    yield put(handledAction)
  }
}

const getInvoiceUrl = function* (action: IPricingAction) {
  try {
    const { data } = yield call(api.createSubscription, action.subscriptionData)
    // const data = JSON.parse('{"message":"One or more of the billing address fields are missing"}')

    if (
      data.message === 'One or more of the billing address fields are missing'
    ) {
      yield all([
        put({ type: PricingActionsTypes.PRICING_SHOW_BILLING_FORM }),
        put({
          type: PricingActionsTypes.PRICING_GET_INVOICE_SUCCESS,
        }),
      ])
    } else {
      yield all([
        put({ type: PricingActionsTypes.PRICING_HIDE_BILLING_FORM }),
        put({ type: PricingActionsTypes.PRICING_SHOW_INVOICE_MODAL }),
        call(api.getInvoiceUrl, {
          subscription_id: data.external_subscription_id,
          is_top_up: !!action.subscriptionData.amount,
          amount: action.subscriptionData.amount,
        }),
      ])

      /*let shouldRequest = true
      let invoiceResponse

      while (shouldRequest) {
        // @ts-ignore
        invoiceResponse = yield call(api.getInvoiceUrl, {
          subscription_id: data.external_subscription_id,
          is_top_up: !!action.subscriptionData.amount,
          amount: action.subscriptionData.amount,
        })

        yield delay(1000)

        if (
          invoiceResponse.data.message !== 'There are no issued invoices yet'
        ) {
          shouldRequest = false
        }
      }

      yield all([
        put({
          type: PricingActionsTypes.PRICING_GET_INVOICE_SUCCESS,
          invoiceUrl: invoiceResponse.data.invoice_url,
        }),
      ])*/
    }
  } catch (e) {
    const handledAction: Action = yield error.handle(
      { type: PricingActionsTypes.PRICING_GET_INVOICE_FAIL },
      e
    )
    yield put(handledAction)
  }
}

const validateCoupon = function* (action: IPricingAction) {
  try {
    const { data } = yield call(api.validateCoupon, { coupon: action.coupon })

    if (data.message === 'invalid coupon') {
      yield put({
        type: PricingActionsTypes.PRICING_SET_COUPON_VALIDATION_ERROR,
        isCouponInvalid: true,
      })
    } else {
      yield put({
        type: PricingActionsTypes.PRICING_VALIDATE_COUPON_SUCCESS,
        appliedCouponInfo: data,
      })
    }
  } catch (e) {
    const handledAction: Action = yield error.handle(
      { type: PricingActionsTypes.PRICING_VALIDATE_COUPON_FAIL },
      e
    )
    yield put(handledAction)
  }
}

// @ts-ignore
const topUpBalance = function* (action: IPricingAction) {
  try {
    yield call(api.topUpBalance, action.topUpData)

    if (action.topUpData.one_time_operation) {
      yield all([
        put({ type: PricingActionsTypes.PRICING_SHOW_INVOICE_MODAL }),
        put({
          type: PricingActionsTypes.PRICING_TOGGLE_TOP_UP_MODAL,
          isTopUpModalShown: false,
        }),
      ])

      let shouldRequest = true
      let invoiceResponse

      while (shouldRequest) {
        // @ts-ignore
        invoiceResponse = yield call(api.getInvoiceUrl, {
          is_top_up: true,
          amount: action.topUpData.amount,
        })

        yield delay(1000)

        if (
          invoiceResponse.data.message !== 'There are no issued invoices yet'
        ) {
          shouldRequest = false
        }
      }

      yield all([
        put({
          type: PricingActionsTypes.PRICING_GET_INVOICE_SUCCESS,
          invoiceUrl: invoiceResponse.data.invoice_url,
        }),
        put({
          type: PricingActionsTypes.PRICING_TOP_UP_BALANCE_SUCCESS,
        }),
      ])
    } else {
      const [balanceResponse, subscriptionResponse] = yield all([
        call(getUserBalance),
        call(getUserSubscription),
      ])

      yield all([
        put({
          type: UserActionsTypes.USER_GET_BALANCE_SUCCESS,
          balanceInfo: {
            balance: balanceResponse.data.balance,
            accrued: balanceResponse.data.accrued,
            creditSum: balanceResponse.data.credit_sum,
          },
          userOverviewUrl: balanceResponse.data.portal_url,
        }),
        put({
          type: UserActionsTypes.USER_GET_SUBSCRIPTIONS_SUCCESS,
          subscriptionInfo: subscriptionResponse.data,
        }),
      ])

      yield put({
        type: PricingActionsTypes.PRICING_TOP_UP_BALANCE_SUCCESS,
      })
    }
  } catch (e) {
    const handledAction: Action = yield error.handle(
      { type: PricingActionsTypes.PRICING_TOP_UP_BALANCE_FAIL },
      e
    )
    yield put(handledAction)
  }
}

// @ts-ignore
const cancelSubscription = function* () {
  try {
    yield call(api.cancelSubscription)

    const [balanceResponse, subscriptionResponse] = yield all([
      call(getUserBalance),
      call(getUserSubscription),
    ])

    yield all([
      put({
        type: UserActionsTypes.USER_GET_BALANCE_SUCCESS,
        balanceInfo: {
          balance: balanceResponse.data.balance,
          accrued: balanceResponse.data.accrued,
          creditSum: balanceResponse.data.credit_sum,
        },
        userOverviewUrl: balanceResponse.data.portal_url,
      }),
      put({
        type: UserActionsTypes.USER_GET_SUBSCRIPTIONS_SUCCESS,
        subscriptionInfo: subscriptionResponse.data,
      }),
    ])

    yield put({ type: PricingActionsTypes.PRICING_CANCEL_SUBSCRIPTION_SUCCESS })
  } catch (e) {
    const handledAction: Action = yield error.handle(
      { type: PricingActionsTypes.PRICING_CANCEL_SUBSCRIPTION_FAIL },
      e
    )
    yield put(handledAction)
  }
}

// @ts-ignore
const cancelAutoTopUp = function* () {
  try {
    yield call(api.cancelAutoTopUp)

    const { data } = yield call(getUserSubscription)

    yield all([
      put({
        type: UserActionsTypes.USER_GET_SUBSCRIPTIONS_SUCCESS,
        subscriptionInfo: data,
      }),
      put({
        type: PricingActionsTypes.PRICING_CANCEL_AUTO_TOP_UP_SUCCESS,
      }),
    ])
  } catch (e) {
    const handledAction: Action = yield error.handle(
      { type: PricingActionsTypes.PRICING_CANCEL_AUTO_TOP_UP_FAIL },
      e
    )
    yield put(handledAction)
  }
}

function* pricingSaga() {
  yield takeLatest(PricingActionsTypes.PRICING_GET_PLANS, getPlans)
  yield takeLatest(
    PricingActionsTypes.PRICING_GET_PRODUCT_PRICE,
    getProductPrice
  )
  yield takeLatest(PricingActionsTypes.PRICING_GET_INVOICE, getInvoiceUrl)
  yield takeLatest(PricingActionsTypes.PRICING_VALIDATE_COUPON, validateCoupon)
  yield takeLatest(PricingActionsTypes.PRICING_TOP_UP_BALANCE, topUpBalance)
  yield takeLatest(
    PricingActionsTypes.PRICING_CANCEL_SUBSCRIPTION,
    cancelSubscription
  )
  yield takeLatest(
    PricingActionsTypes.PRICING_CANCEL_AUTO_TOP_UP,
    cancelAutoTopUp
  )
}

export default pricingSaga
