import {
  put,
  takeLatest,
  takeEvery,
  call,
  all,
  delay,
} from 'redux-saga/effects'
import { Action } from 'redux'
import * as error from 'utils/error-handler'
import { UserActionsTypes } from './actions'
import * as api from './api'
import Cookies from 'js-cookie'

const OLD_DASHBOARD_AUTH_REDIRECT_URL =
  process.env.REACT_APP_OLD_DASHBOARD_AUTH_REDIRECT_URL

// @ts-ignore
const initUser = function* (action) {
  try {
    const referralParamClickId = Cookies.get('referral_param_click_id')

    if (action.isFirstLogin && !!referralParamClickId) {
      Cookies.remove('referral_param_click_id')
    }

    if (action.isFirstLogin) yield delay(4000)

    // @ts-ignore
    const userInitResponse = yield call(
      api.initUser,
      action.isFirstLogin ? referralParamClickId : undefined,
      action.forceNewDash && action.isFirstLogin
        ? action.forceNewDash
        : undefined
    )

    const [balanceResponse, subscriptionResponse, scrapperKeyResponse] =
      yield all([
        call(api.getUserBalance),
        call(api.getUserSubscription),
        call(api.getScrapperApiKey),
      ])

    if (userInitResponse.data.is_old_user) {
      window.location.replace(
        `${OLD_DASHBOARD_AUTH_REDIRECT_URL}${userInitResponse.data.token}`
      )
    } else {
      yield all([
        put({
          type: UserActionsTypes.USER_GET_BALANCE_SUCCESS,
          balanceInfo: {
            balance: balanceResponse.data.balance,
            accrued: balanceResponse.data.accrued,
            creditSum: balanceResponse.data.credit_sum,
          },
          userOverviewUrl: balanceResponse.data.portal_url,
        }),
        put({
          type: UserActionsTypes.USER_GET_SUBSCRIPTIONS_SUCCESS,
          subscriptionInfo: subscriptionResponse.data,
        }),
        put({
          type: UserActionsTypes.USER_GET_SCRAPPER_API_KEY_SUCCESS,
          scrapperApiKey: scrapperKeyResponse.data.scrapper,
        }),
        put({
          type: UserActionsTypes.USER_INITIALIZE_SUCCESS,
          intercomHash: userInitResponse.data.intercom_hash,
          userHash: userInitResponse.data.user_hash,
        }),
      ])
    }
  } catch (e) {
    const handledAction: Action = yield error.handle(
      { type: UserActionsTypes.USER_INITIALIZE_FAIL },
      e
    )
    yield put(handledAction)
  }
}

const getUserBalance = function* () {
  try {
    const { data } = yield call(api.getUserBalance)

    yield put({
      type: UserActionsTypes.USER_GET_BALANCE_SUCCESS,
      balanceInfo: {
        balance: data.balance,
        accrued: data.accrued,
        creditSum: data.credit_sum,
      },
      userOverviewUrl: data.portal_url,
    })
  } catch (e) {
    const handledAction: Action = yield error.handle(
      { type: UserActionsTypes.USER_GET_BALANCE_FAIL },
      e
    )
    yield put(handledAction)
  }
}

const getUserOverview = function* () {
  try {
    const { data } = yield call(api.getUserOverview)

    yield put({
      type: UserActionsTypes.USER_GET_OVERVIEW_SUCCESS,
      userOverviewUrl: data.portal_url,
    })
  } catch (e) {
    const handledAction: Action = yield error.handle(
      { type: UserActionsTypes.USER_GET_OVERVIEW_FAIL },
      e
    )
    yield put(handledAction)
  }
}

const getScrapperApiKey = function* () {
  try {
    const { data } = yield call(api.getScrapperApiKey)

    yield put({
      type: UserActionsTypes.USER_GET_SCRAPPER_API_KEY_SUCCESS,
      scrapperApiKey: data.scrapper,
    })
  } catch (e) {
    const handledAction: Action = yield error.handle(
      { type: UserActionsTypes.USER_GET_SCRAPPER_API_KEY_FAIL },
      e
    )
    yield put(handledAction)
  }
}

// @ts-ignore
const getProxyPackages = function* () {
  try {
    const [proxyPackagesResponse, availableHostsResponse] = yield all([
      call(api.getProxyPackages),
      call(api.getAvailableHosts),
    ])

    yield put({
      type: UserActionsTypes.USER_GET_PROXY_PACKAGES_SUCCESS,
      proxyPackages: proxyPackagesResponse.data,
      availableHosts: availableHostsResponse.data,
    })
  } catch (e) {
    const handledAction: Action = yield error.handle(
      { type: UserActionsTypes.USER_GET_PROXY_PACKAGES_FAIL },
      e
    )
    yield put(handledAction)
  }
}

const getUserSubscription = function* () {
  try {
    const { data } = yield call(api.getUserSubscription)

    yield put({
      type: UserActionsTypes.USER_GET_SUBSCRIPTIONS_SUCCESS,
      subscriptionInfo: data,
    })
  } catch (e) {
    const handledAction: Action = yield error.handle(
      { type: UserActionsTypes.USER_GET_SUBSCRIPTIONS_FAIL },
      e
    )
    yield put(handledAction)
  }
}

function* userSaga() {
  yield takeLatest(UserActionsTypes.USER_INITIALIZE, initUser)
  yield takeLatest(UserActionsTypes.USER_GET_OVERVIEW, getUserOverview)
  yield takeLatest(UserActionsTypes.USER_GET_BALANCE, getUserBalance)
  yield takeEvery(UserActionsTypes.USER_GET_SUBSCRIPTIONS, getUserSubscription)
  yield takeLatest(
    UserActionsTypes.USER_GET_SCRAPPER_API_KEY,
    getScrapperApiKey
  )
  yield takeLatest(UserActionsTypes.USER_GET_PROXY_PACKAGES, getProxyPackages)
}

export default userSaga
