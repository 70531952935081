/*export const popularPlansId = [
  '9pts9w43u36zQmmp',
  'U9VMKTNzMudysft7',
  'HwnAnHnxVv9goUph',
  'ERchD8ye48ih6nUx',
  'fWbiWyNu22Zmntpo',
  '9RHDyJkfP7e7fwZT',
]*/

import React, { ReactElement } from 'react'

export const popularPlansId = ['kek']

export const minimalPaygTopUp = 4

// export const blackFridayPlansId = ['CWgDx5To8jDybWzZ', 'iMBYZvjNpNkUJp55']
export const blackFridayPlansId = ['kek']

export const workingSubscriptionStatuses = [
  'active',
  'trial',
  'cancelled',
  /*'paused',*/
]

export const pricingStructure = {
  proxy: {
    label: 'Proxies',
    values: [
      {
        label: 'Residential Proxies',
        productId: 'wifi',
      },
      {
        label: 'Mobile Proxies',
        productId: 'mobile',
      },
      {
        label: 'ISP Proxies',
        productId: 'isp',
      },
      {
        label: 'DC Proxies',
        productId: 'dc',
      },
    ],
  },
  scraping: {
    label: 'Scraper APIs',
    values: [
      {
        label: 'eCommerce',
        productId: 'ecommerce',
        description: (
          <ul>
            <li>High Load API</li>
            <li>Walmart</li>
          </ul>
        ),
      },
      {
        label: 'Unblocker',
        productId: 'unblocker',
      },
      {
        label: 'SERP',
        productId: 'serp',
        description: (
          <ul>
            <li>Baidu Search API</li>
            <li>Bing Search API</li>
            <li>DuckDuckGo Search API</li>
            <li>Google Autocomplete API</li>
            <li>Google Images API</li>
            <li>Google Search API</li>
            <li>Google Shopping API</li>
            <li>Google Trends API</li>
            <li>Naver Search API</li>
            <li>Yahoo! Search API</li>
            <li>Yandex Search API</li>
          </ul>
        ),
      },
    ],
  },
}

/*export const pricingStructure = {
  proxy: {
    label: 'Proxies',
    values: [
      {
        label: 'Residential Proxies',
        productId: 'wifi',
      },
      {
        label: 'Mobile Proxies',
        productId: 'mobile',
      },
      {
        label: 'ISP Proxies',
        productId: 'isp',
      },
      {
        label: 'DC Proxies',
        productId: 'dc',
      },
    ],
  },
  scraping: {
    title: 'Scraper API',
    ecommerce: [
      {
        label: '1688 Direct API',
        productId: 'ecommerce_1688_api',
      },
      {
        label: 'Alibaba Direct API',
        productId: 'ecommerce_alibaba_api',
      },
      {
        label: 'eCommerce High Load API',
        productId: 'ecommerce_high_load',
      },
      {
        label: 'Lazada Direct API',
        productId: 'ecommerce_lazada_api',
      },
      /!*{
        label: 'Shopee Direct API',
        productId: 'ecommerce_shopee_unblocker',
      },*!/
      {
        label: 'TaoBao Direct API',
        productId: 'ecommerce_taobao_api',
      },
      {
        label: 'eCommerce Top Tier API',
        productId: 'ecommerce_top_tier',
      },
      {
        label: 'Walmart Direct API',
        productId: 'ecommerce_walmart_unblocker',
      },
    ],
    unblocker: [
      {
        label: 'Unblocker Top Tier API',
        productId: 'unblocker_top_tier',
      },
      /!*{
        label: 'Unblocker Advanced Request',
        productId: 'unblocker_extras_jsexec',
      },*!/
    ],
    serp: [
      {
        label: 'Baidu Search Advanced API',
        productId: 'serp_advanced_baidu_search',
      },
      {
        label: 'Bing Search Advanced API',
        productId: 'serp_advanced_bing_search',
      },
      {
        label: 'DuckDuckGo Search Advanced API',
        productId: 'serp_advanced_duckduckgo_search',
      },
      {
        label: 'Google Autocomplete API',
        productId: 'serp_advanced_google_autocomplete',
      },
      {
        label: 'Google Images Advanced API',
        productId: 'serp_advanced_google_images',
      },
      {
        label: 'Google Search Advanced API',
        productId: 'serp_advanced_google_search',
      },
      {
        label: 'Google Search API',
        productId: 'serp_essential_google_search',
      },
      {
        label: 'Google Shopping Advanced API',
        productId: 'serp_advanced_google_shopping',
      },
      {
        label: 'Google Trends Advanced API',
        productId: 'serp_advanced_google_trends',
      },
      {
        label: 'Naver Search Advanced API',
        productId: 'serp_advanced_naver_search',
      },
      {
        label: 'Yahoo! Search Advanced API',
        productId: 'serp_advanced_yahoo_search',
      },
      {
        label: 'Yandex Search Advanced API',
        productId: 'serp_advanced_yandex_search',
      },
    ],
    social: [
      /!*{
        label: 'Instagram API',
        productId: 'social_extended_instagram',
      },*!/
      /!*{
        label: 'LinkedIn API',
        productId: 'social_extended_linkedin',
      },*!/
      {
        label: 'Reddit API',
        productId: 'social_extended_reddit',
      },
      {
        label: 'Snapchat API',
        productId: 'social_extended_snapchat',
      },
      {
        label: 'TikTok API',
        productId: 'social_extended_tiktok',
      },
      /!*{
        label: 'X (Twitter) API',
        productId: 'social_extended_twitter',
      },*!/
      {
        label: 'YouTube API',
        productId: 'social_extended_youtube',
      },
    ],
    /!*'Business Reviews': [
      {
        label: 'G2 Unblocker API',
        productId: 'business_reviews_g2_unblocker',
      },
    ],
    Tickets: [
      {
        label: 'Ticketmaster Unblocker API',
        productId: 'tickets_ticketmaster_unblocker',
      },
    ],*!/
  },
}*/

export const pricingFeatures = {
  proxy: [
    {
      label: 'Core features',
      items: [
        {
          label: 'HTTP, SOCKS5, UDP, QUIC protocols',
          values: [true, true, true, true, true],
        },
        {
          label: 'Sticky and rotating sessions',
          values: [true, true, true, true, true],
        },
        {
          label: 'Unlimited connections',
          values: [true, true, true, true, true],
        },
        {
          label: 'Look-a-like IP rotation',
          description:
            'When your IP address changes, the system automatically selects a new one that is similar to the previous one. This mimics how real users change locations and networks.',
          values: [true, true, true, true, true],
        },
        {
          label: 'Country, city and ISP targeting',
          values: [true, true, true, true, true],
        },
        {
          label: 'Proxy Management API',
          values: [true, true, true, true, true],
        },
        {
          label: 'Multi-channel support',
          values: [true, true, true, true, true],
        },
      ],
    },
    {
      label: 'Advanced tools',
      items: [
        {
          label: 'Extended Pool Analytics',
          values: [false, false, false, true, true],
        },
        {
          label: 'Customer Success Manager',
          values: [false, false, false, true, true],
        },
      ],
    },
    {
      label: 'Enhanced operations',
      items: [
        {
          label: 'Ext. Success Rate Monitoring',
          description:
            'Monitor and optimise the success rate based on your definition of a successful request',
          values: [false, false, false, false, true],
        },
        {
          label: 'Advanced pool management API',
          description:
            'Ability to customise the node selection process via API',
          values: [false, false, false, false, true],
        },
      ],
    },
  ],
  scraping: [
    {
      label: 'Core features',
      items: [
        {
          label: 'JavaScript/AJAX Handling',
          description:
            'Ensures full data capture from sites with complex loading mechanisms and dynamic content in modern websites.',
          values: [true, true, true, true, true],
        },
        {
          label: 'Proxy Rotation',
          description:
            'Provides access to real residential IPs, ensuring legitimacy and reducing the risk of being blocked',
          values: [true, true, true, true, true],
        },
        {
          label: 'Anti-bot Bypass',
          description:
            'Reduces manual intervention and improves scraping reliability.',
          values: [true, true, true, true, true],
        },
        {
          label: 'Rate Limiting',
          description: 'Respectful scraping with automatic request throttling.',
          values: [true, true, true, true, true],
        },
        {
          label: 'Custom Headers/Cookies',
          description: 'Mimic real users with configurable HTTP requests.',
          values: [true, true, true, true, true],
        },
        {
          label: 'Data Parsing',
          description: 'Raw data is packaged in HTML and JSON formats',
          values: [true, true, true, true, true],
        },
        {
          label: 'Multi-channel support',
          values: [true, true, true, true, true],
        },
      ],
    },
    {
      label: 'Advanced tools',
      items: [
        {
          label: 'AI-Powered Scraper',
          values: [false, false, false, true, true],
        },
        {
          label: 'CAPTCHA Solving',
          description:
            'Automatically bypass CAPTCHAs for uninterrupted scraping.',
          values: [false, false, false, true, true],
        },
        {
          label: 'Browser Fingerprint Management',
          description: 'Avoid detection with customisable browser profiles.',
          values: [false, false, false, true, true],
        },
        {
          label: 'Customer Success Manager',
          values: [false, false, false, true, true],
        },
      ],
    },
    {
      label: 'Enhanced operations',
      items: [
        {
          label: 'Headless Rendering',
          description:
            'Extracts data without the need for a visual browser, resulting in faster and more efficient scraping, especially for large-scale operations.',
          values: [false, false, false, false, true],
        },
        {
          label: 'Customizable Extraction',
          description:
            'Provides flexibility to choose specific data points to scrape from a website, tailoring the process to your exact needs',
          values: [false, false, false, false, true],
        },
        {
          label: 'Extended Success Rate Monitoring',
          values: [false, false, false, false, true],
        },
        {
          label: 'Advanced pool management API',
          values: [false, false, false, false, true],
        },
      ],
    },
  ],
}

export const randomCountry = {
  label: 'Any country',
  value: 'any',
}

export const randomRegion = {
  label: 'Any region',
  value: 'any',
}

export const randomCity = {
  label: 'Any city',
  value: 'any',
}

export const randomIsp = {
  label: 'Any ISP',
  value: 'any',
}

export const plansDescription: {
  [key: string]: string | ReactElement
} = {
  'Pay as You Go': (
    <>
      <b>Starting at $4.00</b> and including all the essential features across
      all the products.
    </>
  ),
  Starter: (
    <>
      Best for startups and SMEs requiring low traffic and{' '}
      <b>all core features.</b>
    </>
  ),
  Advanced: (
    <>
      Higher traffic limits at <b>very competitive rates.</b> Ideal for growing
      businesses.
    </>
  ),
  Professional: (
    <>
      For customers requiring access to <b>advanced tools</b> for smooth
      scaling.
    </>
  ),
  Business: (
    <>
      <b>Enhanced operations</b> for clients using proxies in mission-critical
      processes.
    </>
  ),
}
