import * as React from 'react'
import type { SVGProps } from 'react'
const SvgHelpCircle = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    fill="none"
    viewBox="0 0 28 28"
    {...props}
  >
    <path
      fill="currentColor"
      d="M19.225 21.02a.477.477 0 0 0 .04-.722L17.89 18.92a.53.53 0 0 0-.671-.055c-.952.63-2.071.97-3.219.967a5.8 5.8 0 0 1-3.219-.967.53.53 0 0 0-.67.055l-1.377 1.377a.477.477 0 0 0 .041.722A8.7 8.7 0 0 0 14 22.75a8.7 8.7 0 0 0 5.225-1.73m1.073-1.754a.477.477 0 0 0 .722-.041A8.7 8.7 0 0 0 22.75 14a8.7 8.7 0 0 0-1.73-5.225.477.477 0 0 0-.722-.04L18.92 10.11a.53.53 0 0 0-.055.67c.612.923.968 2.03.968 3.219s-.356 2.296-.968 3.219a.53.53 0 0 0 .055.67zM19.266 7.702a.477.477 0 0 0-.041-.722A8.7 8.7 0 0 0 14 5.25a8.7 8.7 0 0 0-5.225 1.73.477.477 0 0 0-.04.722l1.376 1.377c.178.179.46.194.67.055A5.8 5.8 0 0 1 14 8.167c1.19 0 2.296.356 3.22.968.21.139.49.123.669-.055zM7.702 8.734a.477.477 0 0 0-.722.04A8.7 8.7 0 0 0 5.25 14c0 1.959.644 3.768 1.73 5.224a.477.477 0 0 0 .722.042L9.08 17.89a.53.53 0 0 0 .055-.671A5.8 5.8 0 0 1 8.167 14c0-1.19.356-2.296.968-3.22a.53.53 0 0 0-.055-.67zm9.124 8.606q.278-.236.514-.514A4.36 4.36 0 0 0 18.375 14a4.36 4.36 0 0 0-1.549-3.34A4.36 4.36 0 0 0 14 9.625a4.36 4.36 0 0 0-3.34 1.549A4.36 4.36 0 0 0 9.625 14a4.36 4.36 0 0 0 1.549 3.34A4.36 4.36 0 0 0 14 18.375c1.078 0 2.064-.39 2.826-1.035M14 24.208a10.208 10.208 0 1 1 0-20.416 10.208 10.208 0 0 1 0 20.416"
      opacity={0.8}
    />
  </svg>
)
export default SvgHelpCircle
