import React, { useEffect, useState } from 'react'
import { Tooltip } from 'react-tooltip'
import { useSelector } from 'react-redux'

import styles from './PlansComparisonTable.module.scss'

import Text from 'components/elements/typography/Text'
import CustomButton from 'components/elements/buttons/CustomButton'
import { ICustomButtonProps } from 'components/elements/buttons/CustomButton/CustomButton'

import {
  popularPlansId,
  blackFridayPlansId,
  pricingStructure,
} from 'utils/constants'
import { Cross as CrossIcon, Check as CheckIcon } from '../../../icons'
import { getPriceWithoutCents, getNormalizedPrice } from 'utils/functions'
import { ISubscriptionPlan } from 'models/pricing'
import { selectSubscriptionProcessing } from 'store/pricing/selectors'
import {
  selectSubscriptionInfo,
  selectSubscriptionInfoFetching,
} from 'store/user/selectors'
import { ProxyPoolDescription } from 'utils/proxy/proxyConstants'
import { pricingFeatures, plansDescription } from 'utils/constants'
import TabsList from '../../../elements/tabs/TabsList'

interface IPlansComparisonTable {
  plans: any[]
  activePriceType: string
  className?: string
  processingPlanId?: string | null
  onPlanSelect: (plan: ISubscriptionPlan) => void
  onOuterTabChange: (index: number) => void
}

const productTypesTabItems = [
  {
    label: 'Proxy features',
    value: 'proxy',
  },
  {
    label: 'Scraper APIs features',
    value: 'scraping',
  },
]

const PlansComparisonTable: React.FC<IPlansComparisonTable> = (props) => {
  const {
    plans,
    activePriceType,
    processingPlanId,
    onPlanSelect,
    onOuterTabChange,
    className,
  } = props

  const [activeProductTypeTabIndex, setActiveProductTypeTabIndex] = useState(0)

  const subscriptionProcessing = useSelector(selectSubscriptionProcessing)
  const userSubscriptionInfo = useSelector(selectSubscriptionInfo)
  const userSubscriptionInfoFetching = useSelector(
    selectSubscriptionInfoFetching
  )

  const handleTabChange = (index: number) => {
    setActiveProductTypeTabIndex(index)

    if (activePriceType === 'scraping' && index === 0) {
      onOuterTabChange(0)
    } else if (activePriceType !== 'scraping' && index === 1) {
      onOuterTabChange(4)
    }
  }

  useEffect(() => {
    if (activePriceType === 'scraping' && activeProductTypeTabIndex === 0) {
      setActiveProductTypeTabIndex(1)
    } else if (
      activePriceType !== 'scraping' &&
      activeProductTypeTabIndex === 1
    ) {
      setActiveProductTypeTabIndex(0)
    }
  }, [activePriceType, activeProductTypeTabIndex])

  const isTrialAvailableForPlan = (plan: any) => {
    return !!plan.trial_price && plan.type && plan.type !== 'payg'
  }

  const activeSubscriptionPlanId =
    userSubscriptionInfo?.status === 'active' ||
    userSubscriptionInfo?.status === 'paused' ||
    //TODO: for this case btnLabel will be 'Complete payment'
    (userSubscriptionInfo?.status === 'trial' &&
      !userSubscriptionInfo?.full_plan_id)
      ? userSubscriptionInfo?.plan_id
      : null

  const renderButton = (plan: ISubscriptionPlan) => {
    let btnLabel: string
    let btnColor: ICustomButtonProps['color'] = 'outline'
    let isPermanentlyDisabled: boolean = false

    if (
      userSubscriptionInfo?.status === 'trial' &&
      plan.id === userSubscriptionInfo?.full_plan_id
    ) {
      btnLabel = 'Activate'
      btnColor = 'blue'
    } else if (plan.id === activeSubscriptionPlanId) {
      btnLabel = 'Current'
      isPermanentlyDisabled = true
    } else if (plan.type === 'payg') {
      btnLabel = `Buy now`
    } else if (isTrialAvailableForPlan(plan)) {
      btnLabel = `Try now`
    } else {
      btnLabel = 'Subscribe'
    }

    if (!!popularPlansId.length && popularPlansId.includes(plan.id)) {
      btnColor = 'blue'
    } else if (
      !!blackFridayPlansId.length &&
      blackFridayPlansId?.includes(plan.id)
    ) {
      btnColor = 'black'
    }

    return (
      <CustomButton
        size={'m'}
        color={btnColor}
        largeRadius={true}
        className={styles.planBtn}
        loading={plan.id === processingPlanId || userSubscriptionInfoFetching}
        disabled={subscriptionProcessing || isPermanentlyDisabled}
        onClick={() => handlePlanClick(plan)}
      >
        {btnLabel}
      </CustomButton>
    )
  }

  const getPlanName = (plan: ISubscriptionPlan) => {
    if (plan.type === 'payg') {
      return 'PAYG'
    } else {
      return plan.name
    }
  }

  const getPlanPrice = (plan: any) => {
    return plan.type === 'payg'
      ? '4'
      : getPriceWithoutCents(plan?.amount.monthly)
  }

  const getNormalizedPlanPrice = (
    plan: ISubscriptionPlan,
    type: { label?: string; productId: any }
  ) => {
    if (!!plan?.prices.monthly.proxy) {
      return getNormalizedPrice(plan.prices.monthly.proxy[type.productId])
    } else {
      return 'n/a'
    }
  }

  const getPlanMonthlyPrice = (plan: ISubscriptionPlan) =>
    +getPriceWithoutCents(plan?.amount.monthly)

  const getPlanTrafficAmount = (
    plan: ISubscriptionPlan,
    type: { label?: string; productId: any }
  ) => {
    let planMonthlyPrice: number

    let planPrice = plan?.prices?.monthly?.proxy
      ? +getNormalizedPrice(plan.prices.monthly.proxy[type.productId])
      : null

    if (plan.type === 'payg') {
      planMonthlyPrice = 4
    } else {
      planMonthlyPrice = getPlanMonthlyPrice(plan)
    }

    const traffic = !!planPrice
      ? Math.floor(+planMonthlyPrice / +planPrice)
      : 'n/a'

    if (activeProductTypeTabIndex === 0) {
      return `${traffic} GB`
    } else if (activeProductTypeTabIndex) {
      return `${traffic} K`
    } else {
      return ''
    }
  }

  const handlePlanClick = (plan: any) => {
    onPlanSelect(plan)
  }

  if (!plans?.length) return null

  const plansUpperList = plans.map((plan, index) => (
    <div
      className={`
        ${styles.tableCol}
        ${popularPlansId.includes(plan.id) ? styles.highlighted : ''}
      `}
      key={`plans-comp-upper_${plan.id}`}
    >
      <div className={styles.colHeader}>
        <div className={styles.planHeader}>
          <Text weight={'medium'} className={styles.planName}>
            {getPlanName(plan)}
          </Text>

          <Text className={styles.planDescription}>
            ${getPlanPrice(plan)}
            {plan.type === 'payg' ? <>{` `}or more</> : <>/mo</>}
          </Text>

          {renderButton(plan)}
        </div>
      </div>
      <div className={styles.cell}>
        <Text size={'m'}>{plansDescription[plan.name]}</Text>
      </div>
    </div>
  ))

  const proxyLabelsList = pricingStructure.proxy.values.map((type, index) => (
    <div className={styles.cell} key={`proxy-type-cell_${type.label}_${index}`}>
      {type.label}
    </div>
  ))

  const proxyPriceList = plans.map((plan, index) => (
    <div
      className={`
        ${styles.tableCol}
        ${popularPlansId.includes(plan.id) ? styles.highlighted : ''}
      `}
      key={`plans-proxy-comp-col_${plan.id}_${index}`}
    >
      {pricingStructure.proxy.values.map((type, index) => (
        <div
          className={styles.cell}
          key={`plans-comp-cell_${plan.id}_${index}-${type.productId}`}
        >
          <Text size={'m'} className={styles.planCellPrice}>
            ${getNormalizedPlanPrice(plan, type)}
          </Text>
          <Text className={styles.planCellTraffic} size={'s'}>
            ≈{getPlanTrafficAmount(plan, type)}
          </Text>
        </div>
      ))}
    </div>
  ))

  const renderProxyTable = () => {
    return (
      <>
        <div className={styles.subheader}>
          <div className={styles.tableCol}>
            <div className={styles.subheaderTitle}>
              {pricingStructure.proxy.label}
              {/*<div
                className={styles.infoIconWrapper}
                data-tip
                data-tooltip-id={`comparison_table_proxy_info`}
              >
                <InfoIcon />
              </div>*/}
            </div>
          </div>
          <div className={styles.tableCol}>
            {/*<div className={styles.subheaderDescription}>Price per 1 GB</div>*/}
          </div>
        </div>

        <div className={styles.table}>
          <div className={styles.tableCol}>{proxyLabelsList}</div>

          {proxyPriceList}
        </div>
      </>
    )
  }

  const scrapingLabelsList = pricingStructure.scraping.values.map(
    (type, index) => (
      <div
        className={styles.cell}
        key={`scraping-type-cell_${type.label}_${index}`}
      >
        {type.description ? (
          <>
            <div
              className={styles.tooltipLabelWrapper}
              data-tip
              data-tooltip-id={`product-item-cell-label_${type.label}_${index}`}
            >
              {type.label}
            </div>
            <Tooltip
              id={`product-item-cell-label_${type.label}_${index}`}
              place={'bottom'}
              className={styles.tooltip}
            >
              {type.description}
            </Tooltip>
          </>
        ) : (
          type.label
        )}
      </div>
    )
  )

  // const getProductPrices = (plan: ISubscriptionPlan, productKey: string) => {
  //   if (productKey && plan.prices?.monthly[productKey]) {
  //     return Object.values(plan.prices.monthly[productKey])
  //   } else {
  //     return null
  //   }
  // }

  const scrapingPriceList = plans.map((plan, index) => {
    return (
      <div
        className={`
        ${styles.tableCol}
        ${popularPlansId.includes(plan.id) ? styles.highlighted : ''}
      `}
        key={`plans-scraping-comp-col_${plan.id}_${index}`}
      >
        {pricingStructure.scraping.values.map((type, index) => {
          const price =
            !!plan.prices?.monthly?.scrapper &&
            !!plan.prices?.monthly?.scrapper[type.productId]
              ? plan.prices?.monthly?.scrapper[type.productId]
              : null

          let planMonthlyPrice: number

          if (plan.type === 'payg') {
            planMonthlyPrice = 4
          } else {
            planMonthlyPrice = getPlanMonthlyPrice(plan)
          }

          const requestsAmount = !!price
            ? Math.floor(+planMonthlyPrice / +price)
            : 'n/a'

          return (
            <div
              className={styles.cell}
              key={`plans-scraping-comp-cell_${plan.id}_${index}-${type.productId}`}
            >
              <div>
                <div>
                  <Text size={'m'} className={styles.planCellPrice}>
                    ${price}
                  </Text>
                  <Text className={styles.planCellTraffic} size={'s'}>
                    ≈{requestsAmount} k
                  </Text>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    )
  })

  const renderScrapingTable = () => {
    return (
      <>
        <div className={styles.subheader}>
          <div className={styles.tableCol}>
            <div className={styles.subheaderTitle}>
              {pricingStructure.scraping.label}

              {/*<div
                className={styles.infoIconWrapper}
                data-tip
                data-tooltip-id={`scraping_price_notice`}
              >
                <InfoIcon />
              </div>*/}
            </div>
          </div>
        </div>

        <div className={styles.table}>
          <div className={styles.tableCol}>{scrapingLabelsList}</div>

          {scrapingPriceList}
        </div>
      </>
    )
  }

  const renderFeatures = () => {
    const features =
      activeProductTypeTabIndex === 0
        ? pricingFeatures.proxy
        : pricingFeatures.scraping

    return features.map((featuresGroup, index) => {
      return (
        <div
          key={`faetures-group-container_${featuresGroup}_${index}`}
          className={styles.subTable}
        >
          <div className={styles.subheader}>
            <div className={styles.tableCol}>
              <div className={styles.subheaderTitle}>{featuresGroup.label}</div>
            </div>
          </div>

          <div className={styles.table}>
            <div className={styles.tableCol}>
              {featuresGroup.items.map((item: any, index: number) => (
                <div
                  className={styles.cell}
                  key={`feature-item-cell-label_${item.label}_${index}`}
                >
                  {item.description ? (
                    <>
                      <div
                        className={styles.tooltipLabelWrapper}
                        data-tip
                        data-tooltip-id={`feature-item-cell-label_${item.label}_${index}`}
                      >
                        {item.label}
                      </div>
                      <Tooltip
                        id={`feature-item-cell-label_${item.label}_${index}`}
                        place={'bottom'}
                      >
                        {item.description}
                      </Tooltip>
                    </>
                  ) : (
                    item.label
                  )}
                </div>
              ))}
            </div>

            {plans.map((plan: ISubscriptionPlan, planIndex: number) => (
              <div
                className={styles.tableCol}
                key={`feature-item-col-label_${plan.id}_${planIndex}`}
              >
                {featuresGroup.items.map((item: any, index: number) => (
                  <div
                    className={styles.cell}
                    key={`feature-item-cell-value_${plan.id}_${item.label}_${index}`}
                  >
                    <div className={styles.featureIconContainer}>
                      {item.values[planIndex] ? (
                        <CheckIcon className={styles.checkIcon} />
                      ) : (
                        <CrossIcon className={styles.crossIcon} />
                      )}
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      )
    })
  }

  return (
    <div
      className={`
        ${styles.container}
        ${className || ''}
      `}
    >
      <Text size={'xxxl'} weight={'bold'} className={styles.title}>
        Compare plans
      </Text>

      <div className={styles.tabsContainer}>
        <TabsList
          className={styles.tabs}
          itemClassName={styles.tabsItem}
          items={productTypesTabItems}
          activeTabIndex={activeProductTypeTabIndex}
          size={'l'}
          color={'blue'}
          type={'bordered'}
          onChange={handleTabChange}
        />
      </div>

      <div className={styles.tableContainer}>
        <div className={styles.tableWrapper}>
          <div
            className={`
          ${styles.table}
          ${styles.upperTable}
        `}
          >
            <div className={styles.tableCol}>
              <div className={styles.colHeader}>
                <div className={styles.planHeader}>
                  <Text weight={'medium'} className={styles.planName}>
                    All-in-one Plans
                  </Text>

                  <Text className={styles.planDescription}>
                    With our bundled plans you can access all SOAX proxy and
                    Scraper APIs products
                  </Text>
                </div>
              </div>

              <div className={styles.cell}>Best for</div>
            </div>

            {plansUpperList}
          </div>

          {activeProductTypeTabIndex === 1
            ? renderScrapingTable()
            : renderProxyTable()}

          {renderFeatures()}

          {/*{activePriceType !== 'scraping' ? (
            <div className={styles.subTable}>
              <div className={styles.table}>
                <div className={styles.tableCol}>
                  <div className={styles.cell}>
                    <div className={styles.cellTitle}>
                      Access to all SOAX{' '}
                      <a
                        href="https://soax.com/scraping"
                        target={'_blank'}
                        rel={'noopener noreferrer'}
                      >API products</a>
                    </div>
                  </div>
                </div>
                <div className={styles.tableCol}>
                  <div className={styles.cell}>
                    <div className={styles.featureIconContainer}>
                      <CheckIcon className={styles.checkIcon} />
                    </div>
                  </div>
                </div>
                <div className={styles.tableCol}>
                  <div className={styles.cell}>
                    <div className={styles.featureIconContainer}>
                      <CheckIcon className={styles.checkIcon} />
                    </div>
                  </div>
                </div>
                <div className={styles.tableCol}>
                  <div className={styles.cell}>
                    <div className={styles.featureIconContainer}>
                      <CheckIcon className={styles.checkIcon} />
                    </div>
                  </div>
                </div>
                <div className={styles.tableCol}>
                  <div className={styles.cell}>
                    <div className={styles.featureIconContainer}>
                      <CheckIcon className={styles.checkIcon} />
                    </div>
                  </div>
                </div>
                <div className={styles.tableCol}>
                  <div className={styles.cell}>
                    <div className={styles.featureIconContainer}>
                      <CheckIcon className={styles.checkIcon} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}*/}
        </div>
      </div>

      <Tooltip id={`comparison_table_proxy_info`} place={'bottom'}>
        {ProxyPoolDescription}
      </Tooltip>

      <Tooltip id={`scraping_price_notice`} place={'bottom'}>
        The cost of using the Scraper APIs is calculated per every thousand
        requests. For example, if the price is listed as $2.10 per 1,000
        requests, it means that 1 successful request to the selected API costs
        $0.0021
      </Tooltip>
    </div>
  )
}

export default PlansComparisonTable
