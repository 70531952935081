import React from 'react'

import styles from './PlanStatus.module.scss'

import { SubscriptionStatus, SubscriptionActivationStatus } from 'models/user'

import Loader from 'components/elements/progress/Loader'

interface IPlanStatusProps {
  status: SubscriptionStatus
  activationStatus: SubscriptionActivationStatus
  className?: string
}

const statusLabels = {
  active: 'Active',
  trial: 'Trial',
  paused: 'Paused',
  expired: 'Expired',
  blocked: 'BLocked',
  cancelled: 'Cancelled',
  unpaid: 'Unpaid',
}

const PlanStatus: React.FC<IPlanStatusProps> = ({
  status,
  activationStatus,
  className,
}) => {
  if (!status) return null

  if (activationStatus === 'in_progress') {
    return (
      <div
        className={`
        ${styles.status}
        ${styles.activating}
        ${className || ''}
      `}
      >
        <Loader classname={styles.loader} />
        Updating
      </div>
    )
  } else {
    return (
      <div
        className={`
        ${styles.status}
        ${styles[status]}
        ${className || ''}
      `}
      >
        {statusLabels[status] || ''}
      </div>
    )
  }
}

export default PlanStatus
