import React, { useEffect } from 'react'
import { useIntercom } from 'react-use-intercom'
import { useSearchParams } from 'react-router-dom'

// import styles from './AuthLayout.module.scss'

export interface IMainLayout {
  children: React.ReactNode
}

const AuthLayout: React.FC<IMainLayout> = (props) => {
  const { children } = props

  const { show } = useIntercom()
  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    if (searchParams.has('action')) {
      let actionParams = searchParams.getAll('action')

      const newParams: { [key: string]: string } = {}

      if (actionParams.indexOf('open_intercom') >= 0) {
        show()

        searchParams.forEach((value: string, key: string) => {
          if (key !== 'action' && value !== 'open_intercom') {
            newParams[key] = value
          } else if (key === 'action' && value !== 'open_intercom') {
            newParams[key] = value
          }
        })
      } else {
        searchParams.forEach((value: string, key: string) => {
          newParams[key] = value
        })
      }

      setSearchParams(newParams)
    }
  }, [searchParams, setSearchParams, show])

  return <section>{children}</section>
}

export default AuthLayout
