import * as React from 'react'
import type { SVGProps } from 'react'
const SvgThumbUp = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    fill="none"
    viewBox="0 0 28 28"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12.486 6.125a.54.54 0 0 0-.47.273L8.114 13.25a4.96 4.96 0 0 0-.62 2.996l.412 3.749a1.44 1.44 0 0 0 1.263 1.275l2.47.294c1.63.194 3.284.025 4.842-.493a2.74 2.74 0 0 0 1.682-1.594l1.988-5.053a1.658 1.658 0 0 0-1.826-2.24l-3.985.692a1.823 1.823 0 0 1-2.1-2.146l.776-3.961a.54.54 0 0 0-.53-.644m-1.99-.594a2.29 2.29 0 0 1 4.237 1.574l-.777 3.962a.1.1 0 0 0 .001.035q.004.014.019.03.015.015.03.019a.1.1 0 0 0 .034.001l3.985-.692c2.27-.394 4.27 1.532 3.96 3.816q-.056.407-.206.789l-1.988 5.052a4.49 4.49 0 0 1-2.76 2.615c-1.801.6-3.714.794-5.6.57l-2.47-.295a3.19 3.19 0 0 1-2.796-2.82l-.412-3.75a6.7 6.7 0 0 1 .84-4.054z"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgThumbUp
