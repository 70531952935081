import React, { ReactElement } from 'react'
import { Tooltip } from 'react-tooltip'

import styles from './FeaturesBlock.module.scss'

import { TProxyTypes } from 'models/pricing'

import {
  Server as ServerIcon,
  Xml as XmlIcon,
  Link as LinkIcon,
  Refresh as RefreshIcon,
  Location as LocationIcon,
  Language as LanguageIcon,
  Diamond as DiamondIcon,
  HelpCircle as HelpIcon,
  CaretUp as CaretUpIcon,
  Sort as SortIcon,
  Unlock as UnlockIcon,
  Timer as TimerIcon,
  Document as DocumentIcon,
  Edit as EditIcon,
} from '../../../icons'

interface IFeaturesBlockProps {
  activePlanType: TProxyTypes | null
  className?: string
}

type TFeature = {
  label: string
  icon: ReactElement
  info?: string | ReactElement
}

const FeaturesBlock: React.FC<IFeaturesBlockProps> = (props) => {
  const { activePlanType, className } = props

  const features = {
    default: [
      {
        label: 'All Proxy types',
        icon: <ServerIcon />,
        info: (
          <ul className={styles.tootipList}>
            <li>Residential proxies</li>
            <li>Mobile proxies</li>
            <li>ISP USA proxies</li>
            <li>Datacenter (DC) proxies</li>
          </ul>
        ),
      },
      {
        label: 'All Scraper APIs',
        icon: <XmlIcon />,
        info: (
          <ul className={styles.tootipList}>
            <li>Web Unblocker API</li>
            <li>SERP API</li>
            <li>eCommerce API</li>
          </ul>
        ),
      },
      {
        label: 'HTTP, SOCKS5, UDP, QUIC',
        icon: <LinkIcon />,
      },
      {
        label: 'Sticky and rotating sessions',
        icon: <RefreshIcon />,
      },
      {
        label: 'Country-region-city-ISP target',
        icon: <LocationIcon />,
      },
      {
        label: 'All Geo (180+)',
        icon: <LanguageIcon />,
      },
      {
        label: 'Unlimited proxy connections',
        icon: <DiamondIcon />,
      },
      {
        label: '24/7 multichannel support',
        icon: <HelpIcon />,
      },
    ],
    isp: [
      {
        label: 'All Proxy types',
        icon: <ServerIcon />,
        info: (
          <ul className={styles.tootipList}>
            <li>Residential proxies</li>
            <li>Mobile proxies</li>
            <li>ISP USA proxies</li>
            <li>Datacenter (DC) proxies</li>
          </ul>
        ),
      },
      {
        label: 'All Scraper APIs',
        icon: <XmlIcon />,
        info: (
          <ul className={styles.tootipList}>
            <li>Web Unblocker API</li>
            <li>SERP API</li>
            <li>eCommerce API</li>
          </ul>
        ),
      },
      {
        label: 'HTTP, SOCKS5, UDP, QUIC',
        icon: <LinkIcon />,
      },
      {
        label: 'Sticky and rotating sessions',
        icon: <RefreshIcon />,
      },
      {
        label: 'USA ISP proxy 2.6M+ pool',
        icon: <LocationIcon />,
      },
      {
        label: 'High success rates',
        icon: <CaretUpIcon />,
      },
      {
        label: 'Unlimited proxy connections',
        icon: <DiamondIcon />,
      },
      {
        label: '24/7 multichannel support',
        icon: <HelpIcon />,
      },
    ],
    dc: [
      {
        label: 'All Proxy types',
        icon: <ServerIcon />,
        info: (
          <ul className={styles.tootipList}>
            <li>Residential proxies</li>
            <li>Mobile proxies</li>
            <li>ISP USA proxies</li>
            <li>Datacenter (DC) proxies</li>
          </ul>
        ),
      },
      {
        label: 'All Scraper APIs',
        icon: <XmlIcon />,
        info: (
          <ul className={styles.tootipList}>
            <li>Web Unblocker API</li>
            <li>SERP API</li>
            <li>eCommerce API</li>
          </ul>
        ),
      },
      {
        label: 'HTTP, SOCKS5, UDP, QUIC',
        icon: <LinkIcon />,
      },
      {
        label: 'Sticky and rotating sessions',
        icon: <RefreshIcon />,
      },
      {
        label: 'GEO - 7 countries',
        icon: <LocationIcon />,
        info: (
          <ul className={styles.tootipList}>
            <li>United States (US)</li>
            <li>Germany (DE)</li>
            <li>United Kingdom (UK)</li>
            <li>France (FR)</li>
            <li>Poland (PL)</li>
            <li>Spain (ES)</li>
            <li>Japan (JP)</li>
          </ul>
        ),
      },
      {
        label: 'High success rates',
        icon: <CaretUpIcon />,
      },
      {
        label: 'Unlimited proxy connections',
        icon: <DiamondIcon />,
      },
      {
        label: '24/7 multichannel support',
        icon: <HelpIcon />,
      },
    ],
    scraping: [
      {
        label: 'All Scraper APIs',
        icon: <XmlIcon />,
        info: (
          <ul className={styles.tootipList}>
            <li>Web Unblocker API</li>
            <li>SERP API</li>
            <li>eCommerce API</li>
          </ul>
        ),
      },
      {
        label: 'JavaScript/AJAX Handling',
        icon: <SortIcon />,
      },
      {
        label: 'Anti-bot Bypass',
        icon: <UnlockIcon />,
      },
      {
        label: 'Rate Limiting',
        icon: <TimerIcon />,
      },
      {
        label: 'All Proxy types',
        icon: <ServerIcon />,
        info: (
          <ul className={styles.tootipList}>
            <li>Residential proxies</li>
            <li>Mobile proxies</li>
            <li>ISP USA proxies</li>
            <li>Datacenter (DC) proxies</li>
          </ul>
        ),
      },
      {
        label: 'Data Parsing',
        icon: <DocumentIcon />,
      },
      {
        label: 'Custom Headers/Cookies',
        icon: <EditIcon />,
      },
      {
        label: '24/7 multichannel support',
        icon: <HelpIcon />,
      },
    ],
  }

  const getType = () => {
    if (activePlanType === 'wifi') {
      return 'Residential'
    } else if (activePlanType === 'mobile') {
      return 'Mobile'
    } else if (activePlanType === 'isp') {
      return 'ISP USA'
    } else if (activePlanType === 'dc') {
      return 'Datacenter'
    } else if (activePlanType === 'scraping') {
      return 'scraping'
    } else {
      return null
    }
  }

  const getFeatures = () => {
    if (activePlanType === 'wifi' || activePlanType === 'mobile') {
      return features.default
    } else if (activePlanType === 'isp') {
      return features.isp
    } else if (activePlanType === 'dc') {
      return features.dc
    } else if (activePlanType === 'scraping') {
      return features.scraping
    } else {
      return null
    }
  }

  if (!getType()) return null

  const featuresList = getFeatures()?.map((feature: TFeature, index) => (
    <div className={styles.feature} key={`features-block-item_${index}`}>
      <div className={styles.featureIconWrapper}>{feature.icon}</div>
      {!!feature.info ? (
        <div
          data-tip
          data-tooltip-id={`feature_tooltip_${index}`}
          className={`${styles.featureContent} ${styles.underlined}`}
        >
          {feature.label}
        </div>
      ) : (
        <div className={styles.featureContent}>{feature.label}</div>
      )}

      {!!feature.info && (
        <Tooltip
          id={`feature_tooltip_${index}`}
          className={'tooltip_light'}
          place={'bottom'}
        >
          {feature.info}
        </Tooltip>
      )}
    </div>
  ))

  return (
    <div
      className={`
        ${styles.container}
        ${className || ''}
      `}
    >
      <h3 className={styles.title}>Every plan includes</h3>
      <div className={styles.featuresList}>{featuresList}</div>
    </div>
  )
}

export default FeaturesBlock
