import { ServiceActionsTypes } from './actions'
import { IServiceAction, IServiceState } from 'models/service'

const initialState: IServiceState = {
  fetching: false,
  locationsFetching: false,
  locations: null,
  locationTargetsFetching: false,
  locationTargets: null,
  isMaintenanceMode: false,
  proxyCheckerResult: null,
  proxyCheckerLoading: false,
  wasProxyCheckerActivated: false,
}

const serviceReducer = (
  state: IServiceState = initialState,
  action: IServiceAction
) => {
  switch (action.type) {
    case ServiceActionsTypes.SERVICE_GET_LOCATIONS:
      return {
        ...state,
        locationsFetching: true,
      }
    case ServiceActionsTypes.SERVICE_GET_LOCATIONS_SUCCESS:
      return {
        ...state,
        locationsFetching: false,
        locations: action.locations,
      }
    case ServiceActionsTypes.SERVICE_GET_LOCATIONS_FAIL:
      return {
        ...state,
        locationsFetching: false,
      }

    case ServiceActionsTypes.SERVICE_GET_LOCATION_TARGETS:
      return {
        ...state,
        locationTargetsFetching: true,
        locationTargets: null,
      }
    case ServiceActionsTypes.SERVICE_GET_LOCATION_TARGETS_SUCCESS:
      return {
        ...state,
        locationTargetsFetching: false,
        locationTargets: action.locationTargets,
      }
    case ServiceActionsTypes.SERVICE_GET_LOCATION_TARGETS_FAIL:
      return {
        ...state,
        locationTargetsFetching: false,
        locationTargets: null,
      }

    case ServiceActionsTypes.SERVICE_CHECK_PROXY:
      return {
        ...state,
        proxyCheckerLoading: true,
      }
    case ServiceActionsTypes.SERVICE_CHECK_PROXY_SUCCESS:
      return {
        ...state,
        proxyCheckerLoading: false,
        wasProxyCheckerActivated: true,
        proxyCheckerResult: action.data,
      }
    case ServiceActionsTypes.SERVICE_CHECK_PROXY_FAIL:
      return {
        ...state,
        proxyCheckerLoading: false,
      }

    default:
      return state
  }
}

export default serviceReducer
