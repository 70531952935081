import { UserActionsTypes } from 'store/user/actions'
import { PricingActionsTypes } from 'store/pricing/actions'

let reconnects = null

export default function websocketInitialize(store) {
  const url = `${process.env.REACT_APP_WEB_SOCKETS_URL}`
  const socket = new WebSocket(url)

  socket.onopen = () => {
    console.log('Websocket opened')

    socket.send(`{"name":"ping","data":"connect"}`)
  }

  socket.onclose = () => {
    console.log('Websocket closed, try to reconnect')

    clearTimeout(reconnects)

    reconnects = setTimeout(() => {
      console.log('Websocket reconnecting after 30sec')
      websocketInitialize(store)
    }, 5000)
  }

  socket.onerror = (e) => {
    console.log('Websocket error', e)
  }

  socket.onmessage = (e) => {
    console.log('Incoming websocket message:', e)

    const eventData = JSON.parse(e.data)

    if (!eventData) {
      console.log('Invalid websocket message data', e)
      return
    }

    switch (eventData.event) {
      case 'subscription':
        store.dispatch({ type: UserActionsTypes.USER_GET_SUBSCRIPTIONS })
        store.dispatch({ type: UserActionsTypes.USER_GET_BALANCE })
        break
      case 'balance.update':
        // store.dispatch({ type: UserActionsTypes.USER_GET_SUBSCRIPTIONS })
        store.dispatch({ type: UserActionsTypes.USER_GET_BALANCE })
        break
      case 'invoice':
        if (eventData.status === 'ok') {
          store.dispatch({
            type: PricingActionsTypes.PRICING_GET_INVOICE_SUCCESS,
            invoiceUrl: eventData.invoice_url,
          })
        }
        break
      default:
        console.log('Unhandled websocket message data')
    }
  }
}
