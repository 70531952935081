import * as React from 'react'
import type { SVGProps } from 'react'
const SvgShoppingCart = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={24}
    fill="none"
    viewBox="0 0 25 24"
    {...props}
  >
    <path
      fill="#D95A16"
      fillRule="evenodd"
      d="M19.648 5.25H5.835l-1.18-2.115A.75.75 0 0 0 4 2.75H2.5a.75.75 0 0 0 0 1.5h1.06l1.164 2.088L7.41 12.28l.003.006.237.523-2.697 2.877a.75.75 0 0 0 .462 1.258l2.458.281c3.075.352 6.18.352 9.254 0l2.458-.28a.75.75 0 0 0-.17-1.491l-2.458.28a39.3 39.3 0 0 1-8.914 0l-.975-.11 1.98-2.112a1 1 0 0 0 .053-.064l.752.098c1.055.138 2.122.165 3.182.08a9.3 9.3 0 0 0 6.366-3.268l.579-.685a1 1 0 0 0 .053-.072l1.078-1.642c.764-1.164-.071-2.71-1.463-2.71M9.156 11.944a.48.48 0 0 1-.377-.278l-.002-.003-2.22-4.913h13.09a.25.25 0 0 1 .21.387l-1.053 1.604-.549.65a7.8 7.8 0 0 1-5.338 2.741c-.957.076-1.919.052-2.87-.072z"
      clipRule="evenodd"
    />
    <path
      fill="#D95A16"
      d="M7 18.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3M16.5 20a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0"
    />
  </svg>
)
export default SvgShoppingCart
