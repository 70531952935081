import React, { useCallback, useMemo, useState } from 'react'
import { Tooltip } from 'react-tooltip'
import { useSelector } from 'react-redux'

import styles from './ProxyConfigurationForm.module.scss'

import {
  randomCountry,
  randomRegion,
  randomCity,
  randomIsp,
} from 'utils/constants'
import {
  selectLocations,
  selectLocationTargets,
  selectLocationTargetsFetching,
} from 'store/service/selectors'
import { capitalizeWord } from 'utils/functions'
import {
  ispAvailableCountries,
  dcAvailableCountries,
} from 'utils/proxy/proxyConstants'

import { InfoCircle as InfoIcon } from '../../../icons'
import Flag from 'components/elements/other/Flag'
import CustomSelect from 'components/elements/inputs/CustomSelect'
import InputGroup from 'components/elements/forms/InputGroup'
import CustomCheckbox from 'components/elements/inputs/CustomCheckbox'
import CustomInput from 'components/elements/inputs/CustomInput'
import Loader from 'components/elements/progress/Loader'
import { ISelectOption } from 'components/elements/inputs/CustomSelect/CustomSelect'
import CustomButton from '../../../elements/buttons/CustomButton'
import TabsList from '../../../elements/tabs/TabsList'
import { ITabItem } from '../../../elements/tabs/TabsList/TabsList'

interface IProxyConfigurationFormProps {
  selectedProxyType: any
  selectedTimeInterval: any
  customTimeInterval: string
  selectedCountry: any
  selectedRegion: any
  selectedCity: any
  selectedIsp: any
  isBindIPActive: boolean
  activeSessionLengthType: any
  sessionPrefix: string
  connectionsAmount?: number
  isConnectionsShown?: boolean
  availableTimeIntervals: ISelectOption[]
  onTimeIntervalChange: (option: ISelectOption) => void
  onCustomTimeIntervalChange: (val: string) => void
  onCountryChange: (option: ISelectOption) => void
  onRegionChange: (option: ISelectOption) => void
  onCityChange: (option: ISelectOption) => void
  onIspChange: (option: ISelectOption) => void
  onDeepTargetsReset: () => void
  onBindIp: () => void
  onActiveSessionLengthChange: (e: any) => void
  onSessionPrefixChange: (e: any) => void
  onConnectionsAmountChange?: (e: any) => void
}

const sessionTypeTabItems = [
  {
    label: 'Sticky session',
    value: 'same',
  },
  {
    label: 'Rotating session',
    value: 'new',
  },
]

const ProxyConfigurationForm: React.FC<IProxyConfigurationFormProps> = (
  props
) => {
  const {
    selectedProxyType,
    selectedCountry,
    selectedRegion,
    selectedCity,
    selectedIsp,
    activeSessionLengthType,
    selectedTimeInterval,
    customTimeInterval,
    isBindIPActive,
    isConnectionsShown,
    sessionPrefix,
    connectionsAmount,
    availableTimeIntervals,
    onConnectionsAmountChange,
    onCountryChange,
    onRegionChange,
    onCityChange,
    onIspChange,
    onDeepTargetsReset,
    onActiveSessionLengthChange,
    onTimeIntervalChange,
    onCustomTimeIntervalChange,
    onBindIp,
    onSessionPrefixChange,
  } = props

  const allCountries = useSelector(selectLocations)
  const activeLocationTargets = useSelector(selectLocationTargets)
  const deepTargetsFetching = useSelector(selectLocationTargetsFetching)

  const getCountriesOptions = useCallback(() => {
    let options = [randomCountry]

    let availableLocations

    if (selectedProxyType.value === 'dc') {
      availableLocations = dcAvailableCountries
    } else if (selectedProxyType.value === 'isp') {
      availableLocations = ispAvailableCountries
    } else {
      availableLocations = allCountries
    }

    if (availableLocations) {
      Object.values(availableLocations).forEach((location: any) => {
        options.push({
          label: location.name,
          value: location.code,
        })
      })
    }

    options.sort(function (a, b) {
      if (a.label < b.label) {
        return -1
      }
      if (a.label > b.label) {
        return 1
      }
      return 0
    })

    return options
  }, [allCountries, selectedProxyType])

  const countriesOptions = useMemo(
    () => getCountriesOptions(),
    [getCountriesOptions]
  )

  const getOptions = (values: string[], defaultOption: ISelectOption) => {
    const options = [defaultOption]

    if (values) {
      values.forEach((value: string) => {
        options.push({
          label: capitalizeWord(value),
          value: value,
        })
      })
    }

    return options
  }

  const regionOptions = getOptions(activeLocationTargets?.regions, randomRegion)
  const citiesOptions = getOptions(activeLocationTargets?.cities, randomCity)
  const ispOptions = getOptions(activeLocationTargets?.isp, randomIsp)

  const iconedCountriesOptions = countriesOptions.map((item) => ({
    ...item,
    icon:
      item.value === 'any' ? null : (
        <Flag countryCode={item.value} className={styles.optionFlag} />
      ),
  }))

  const [isDeepTargetsActive, toggleDeepTargets] = useState(false)
  const [activeSessionTypeTabIndex, setActiveSessionTypeTabIndex] = useState(0)

  const shouldDeepTargetBeVisible =
    selectedProxyType.value !== 'isp' && selectedProxyType.value !== 'dc'

  const handleDeepTargets = () => {
    if (isDeepTargetsActive) {
      onRegionChange(randomRegion)
      onCityChange(randomCity)
      onIspChange(randomIsp)
    }

    toggleDeepTargets(!isDeepTargetsActive)
  }

  const handleCountryChange = (option: any) => {
    onCountryChange(option)
  }

  const handleRegionChange = (option: any) => {
    onRegionChange(option)
  }

  const handleCityChange = (option: any) => {
    onCityChange(option)
  }

  const handleIspChange = (option: any) => {
    onIspChange(option)
  }

  const handleDeepTargetsReset = () => {
    onDeepTargetsReset()
  }

  const handleTimeIntervalChange = (option: any) => {
    onTimeIntervalChange(option)
  }

  const handleCustomTimeIntervalChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    onCustomTimeIntervalChange(e.target.value)
  }

  const handleCustomTimeIntervalBlur = () => {
    const firstInterval = +availableTimeIntervals[0].value

    const lastInterval =
      +availableTimeIntervals[availableTimeIntervals.length - 2].value

    if (+customTimeInterval < firstInterval) {
      onCustomTimeIntervalChange(firstInterval.toString())
    } else if (+customTimeInterval > lastInterval) {
      onCustomTimeIntervalChange(lastInterval.toString())
    }
  }

  const handleSessionTypeChange = (index: number, item?: ITabItem) => {
    setActiveSessionTypeTabIndex(index)

    if (sessionTypeTabItems[index].value === 'new' && isBindIPActive) {
      onBindIp()
    }

    onActiveSessionLengthChange(sessionTypeTabItems[index].value)
  }

  const handleBindIp = () => {
    if (activeSessionLengthType === 'new' && !isBindIPActive) {
      onActiveSessionLengthChange('same')
    }

    onBindIp()
  }

  const getCustomTimeIntervalPlaceholder = () => {
    const firstInterval = availableTimeIntervals[0].value

    const lastInterval =
      availableTimeIntervals[availableTimeIntervals.length - 2].value

    return `type ${firstInterval}..${lastInterval} secs`
  }

  const renderSessionTypeBlock = () => {
    if (activeSessionTypeTabIndex === 0) {
      return (
        <div className={styles.sessionTypeBlock}>
          <p className={styles.sessionTypeDescription}>
            Sticky sessions keep the same IP for up to a specified duration,
            though they may end early.
          </p>

          <div className={styles.sessionTypeRow}>
            <div className={styles.sessionPeriod}>
              <span className={styles.intervalLabel}>Period</span>

              <div className={styles.periodBlock}>
                <CustomSelect
                  className={styles.timeIntervalSelect}
                  value={selectedTimeInterval}
                  name={'time-interval-select'}
                  isSearchable={false}
                  options={availableTimeIntervals}
                  onChange={handleTimeIntervalChange}
                />

                {selectedTimeInterval.value === 'custom' && (
                  <div className={styles.customTimeIntervalBlock}>
                    <CustomInput
                      className={styles.customTimeIntervalInput}
                      value={customTimeInterval}
                      type={'number'}
                      placeholder={getCustomTimeIntervalPlaceholder()}
                      onChange={handleCustomTimeIntervalChange}
                      onBlur={handleCustomTimeIntervalBlur}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className={styles.bindIpBlock}>
              <CustomCheckbox
                id={'bind_ip_toggle'}
                checked={isBindIPActive}
                onChange={handleBindIp}
              >
                Bind IP
              </CustomCheckbox>

              <div
                className={styles.infoIconWrapper}
                data-tip
                data-tooltip-id={`bind_ip_notice`}
              >
                <InfoIcon />
              </div>
            </div>
          </div>
        </div>
      )
    } else if (activeSessionTypeTabIndex === 1) {
      return (
        <p className={styles.sessionTypeDescription}>
          You will get a new IP on every request you make.
        </p>
      )
    }
  }

  return (
    <div className={styles.form}>
      <div className={styles.formBlock}>
        <div>
          <div className={styles.formLeft}>
            <div className={styles.formNumber}>1</div>
            <div className={styles.formLine} />
          </div>
        </div>

        <div className={styles.formRight}>
          <InputGroup
            labelTop={'Proxy location'}
            className={styles.targetGroup}
            boldLabel
          >
            <CustomSelect
              onChange={handleCountryChange}
              value={selectedCountry}
              placeholder={'start type'}
              isSearchable={true}
              name={'quick-connect-target-country'}
              options={iconedCountriesOptions}
              disabled={selectedProxyType.value === 'isp'}
            />
          </InputGroup>

          {shouldDeepTargetBeVisible && (
            <>
              <div className={styles.deepTargetHeader}>
                <CustomCheckbox
                  id={'deep-targets-toggle'}
                  checked={isDeepTargetsActive}
                  onChange={handleDeepTargets}
                >
                  Use precise targeting
                </CustomCheckbox>

                <div
                  className={`
                    ${styles.deepTargetLoaderContainer}
                    ${deepTargetsFetching ? styles.active : ''}
                  `}
                >
                  <Loader />
                </div>

                {isDeepTargetsActive && (
                  <CustomButton
                    color={'ghostAlt'}
                    className={styles.deepTargetResetBtn}
                    onClick={handleDeepTargetsReset}
                  >
                    Reset
                  </CustomButton>
                )}
              </div>

              <div
                className={`
                  ${styles.deepTargetBlock}
                  ${isDeepTargetsActive ? styles.active : ''}
                `}
              >
                <CustomSelect
                  onChange={handleRegionChange}
                  value={selectedRegion}
                  name={'quick-connect-target-region'}
                  placeholder={'start type'}
                  isSearchable={true}
                  options={regionOptions}
                  className={styles.deepTargetSelect}
                  disabled={selectedCountry.value === 'any'}
                />

                <CustomSelect
                  onChange={handleCityChange}
                  value={selectedCity}
                  name={'quick-connect-target-city'}
                  placeholder={'start type'}
                  isSearchable={true}
                  options={citiesOptions}
                  className={styles.deepTargetSelect}
                  disabled={selectedCountry.value === 'any'}
                />

                <CustomSelect
                  onChange={handleIspChange}
                  value={selectedIsp}
                  name={'quick-connect-target-isp'}
                  placeholder={'start type'}
                  isSearchable={true}
                  options={ispOptions}
                  className={styles.deepTargetSelect}
                  disabled={selectedCountry.value === 'any'}
                />
              </div>
            </>
          )}
        </div>
      </div>

      <div className={styles.formBlock}>
        <div>
          <div className={styles.formLeft}>
            <div className={styles.formNumber}>2</div>
            <div className={styles.formLine} />
          </div>
        </div>

        <div className={styles.formRight}>
          <InputGroup labelTop={'Session type'} boldLabel>
            <div className={styles.groupInnerBlock} />
          </InputGroup>

          <TabsList
            items={sessionTypeTabItems}
            activeTabIndex={activeSessionTypeTabIndex}
            size={'m'}
            type={'bordered'}
            className={styles.sessionTypeTabs}
            wide
            onChange={handleSessionTypeChange}
          />

          {renderSessionTypeBlock()}
        </div>

        <Tooltip id={`bind_ip_notice`} place={'bottom'}>
          <p>
            Empty state - IP will refresh after selected period. If IP goes
            offline earlier, you will be reconnected with a new one immediately.
          </p>
          <br />
          <p>
            Filled state - IP will refresh only after selected period. If it
            goes offline earlier, you will not be reconnected with new IP -
            Sticky IP mode.
          </p>
        </Tooltip>
      </div>

      <div className={styles.formBlock}>
        <div>
          <div className={styles.formLeft}>
            <div className={styles.formNumber}>3</div>
            {isConnectionsShown && <div className={styles.formLine} />}
          </div>
        </div>

        <div className={styles.formRight}>
          <InputGroup
            labelTop={'Session name'}
            name={'session_id_input_group'}
            boldLabel
            description={
              <>
                <b>А random string</b> to create a session in order to keep the
                same proxy for more than one request. Example: <b>rand123456</b>
              </>
            }
          >
            <CustomInput
              value={sessionPrefix}
              disabled={activeSessionLengthType === 'new'}
              onChange={onSessionPrefixChange}
            />
          </InputGroup>
        </div>
      </div>

      {isConnectionsShown && (
        <div className={styles.formBlock}>
          <div>
            <div className={styles.formLeft}>
              <div className={styles.formNumber}>4</div>
            </div>
          </div>

          <div className={styles.formRight}>
            <InputGroup
              labelTop={'Connections amount'}
              childrenDirection={'vertical'}
              boldLabel
            >
              <CustomInput
                value={connectionsAmount}
                type={'number'}
                onChange={onConnectionsAmountChange}
              />
              <span className={styles.inputGroupBottomNotice}>1000 max.</span>
            </InputGroup>
          </div>
        </div>
      )}
    </div>
  )
}

export default ProxyConfigurationForm
