import * as React from 'react'
import type { SVGProps } from 'react'
const SvgDocument = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    fill="none"
    viewBox="0 0 28 28"
    {...props}
  >
    <g fill="currentColor" opacity={0.8}>
      <path d="M18.375 15.167a.875.875 0 0 0-.875-.875h-7a.875.875 0 1 0 0 1.75h7a.875.875 0 0 0 .875-.875M18.375 19.833a.875.875 0 0 0-.875-.875h-7a.875.875 0 1 0 0 1.75h7a.875.875 0 0 0 .875-.875" />
      <path
        fillRule="evenodd"
        d="M8.167 2.625a3.21 3.21 0 0 0-3.208 3.208v16.334a3.21 3.21 0 0 0 3.208 3.208h11.667a3.21 3.21 0 0 0 3.208-3.208V9.296c0-.445-.145-.877-.413-1.231l-3.497-4.63a2.04 2.04 0 0 0-1.63-.81zM6.709 5.833c0-.805.652-1.458 1.458-1.458h8.458v5.13c0 .483.392.875.875.875h3.792v11.787c0 .805-.653 1.458-1.459 1.458H8.168a1.46 1.46 0 0 1-1.458-1.458z"
        clipRule="evenodd"
      />
    </g>
  </svg>
)
export default SvgDocument
