import * as React from 'react'
import type { SVGProps } from 'react'
const SvgCaretUp = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    fill="none"
    viewBox="0 0 28 28"
    {...props}
  >
    <g fill="currentColor" fillRule="evenodd" clipRule="evenodd" opacity={0.8}>
      <path d="m13.197 12.333-7.593 7.59a.5.5 0 0 0 0 .708l.898.898a.5.5 0 0 0 .707 0l6.438-6.436a.5.5 0 0 1 .707 0l6.437 6.436a.5.5 0 0 0 .707 0l.898-.898a.5.5 0 0 0 0-.708l-7.593-7.59a1.136 1.136 0 0 0-1.606 0" />
      <path d="m13.197 6.332-7.593 7.591a.5.5 0 0 0 0 .707l.898.898a.5.5 0 0 0 .707 0l6.438-6.435a.5.5 0 0 1 .707 0l6.437 6.435a.5.5 0 0 0 .707 0l.898-.898a.5.5 0 0 0 0-.707l-7.593-7.59a1.136 1.136 0 0 0-1.606 0" />
    </g>
  </svg>
)
export default SvgCaretUp
