import * as React from 'react'
import type { SVGProps } from 'react'
const SvgLocation = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    fill="none"
    viewBox="0 0 28 28"
    {...props}
  >
    <g fill="currentColor" fillRule="evenodd" clipRule="evenodd" opacity={0.8}>
      <path d="M8.458 11.667a5.542 5.542 0 1 1 11.084 0 5.542 5.542 0 0 1-11.084 0M14 7.875a3.792 3.792 0 1 0 0 7.583 3.792 3.792 0 0 0 0-7.583" />
      <path d="M4.112 10.333a9.67 9.67 0 0 1 9.636-8.875h.504a9.67 9.67 0 0 1 9.636 8.875c.222 2.694-.61 5.37-2.321 7.462l-5.592 6.839a2.55 2.55 0 0 1-3.95 0l-5.592-6.84a10.43 10.43 0 0 1-2.321-7.461m9.636-7.125a7.92 7.92 0 0 0-7.892 7.269 8.68 8.68 0 0 0 1.932 6.21l5.592 6.839c.32.392.92.392 1.24 0l5.592-6.839a8.68 8.68 0 0 0 1.932-6.21 7.92 7.92 0 0 0-7.892-7.269z" />
    </g>
  </svg>
)
export default SvgLocation
