import { toast } from 'react-toastify'

// return true if validate error received
export function handle(data, e, flash = true) {
  if (e.response) {
    const response = e.response

    if (response.status === 401) {
      window.location.replace('/?logout=true')
    } else {
      let message = ''

      if (response.data) {
        if (
          response.data.message &&
          response.data.message === 'Internal server error'
        ) {
          message =
            'Internal server error, please try again later in a few minutes'
        } else if (response.data.message) {
          message = response.data.message
        } else if (response.data.error) {
          message = `Error: ${response.data.error}`
        } else if (response.data.detail) {
          message = `Error: ${response.data.detail}`
        }
      } else if (typeof e === 'string') {
        message = e
      } else {
        message = 'An error occurred, please try again later in a few minutes'
      }

      if (response.data) {
        if (response.data.statusCode >= 500) {
          toast.error(message)
          // console.error(message)
        } else if (
          response.data.statusCode !== 404 &&
          response.status !== 404
        ) {
          toast.warn(message)
          // console.warn(message)
        }
      } else {
        toast.error(message)
        // console.error(e)
      }

      return data
    }
  } else {
    toast.error('An error occurred, please try again later in a few minutes')

    return data
  }
}
