import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import styles from './PricingPage.module.scss'

import Text from 'components/elements/typography/Text'
import PricingContent from 'components/modules/pricing/PricingContent'
import {
  /*Server as ServerIcon,
  Xml as ScrapingIcon,
  Check as CheckIcon,*/
  ShoppingCart as ShoppingIcon,
  Apps as AppsIcon,
  Home as HomeIcon,
  Unlock as UnlockIcon,
  MobilePhone as MobileIcon,
  Binocular as BinocularIcon,
  Server as ServerIcon,
} from 'components/icons'
import { PricingActionsTypes } from 'store/pricing/actions'

/*const products = [
  {
    label: 'Scraper APIs: SERP, Social, eCommerce, Unblocker',
    icon: <ScrapingIcon />,
  },
  {
    label: 'Proxies: Residential, Mobile, ISP, DC',
    icon: <ServerIcon />,
  },
]*/

const products = [
  {
    icon: <ShoppingIcon />,
    label: 'Ecommerce Scraper API',
  },
  {
    icon: <AppsIcon />,
    label: 'Datacenter proxies',
  },
  {
    icon: <HomeIcon />,
    label: 'Residential proxies',
  },
  {
    icon: <UnlockIcon />,
    label: 'Web Unblocker Scraper API',
  },
  {
    icon: <MobileIcon />,
    label: 'Mobile proxies',
  },
  {
    icon: <BinocularIcon />,
    label: 'SERP Scraper API',
  },
  {
    icon: <ServerIcon />,
    label: 'ISP proxies',
  },
]

/*const features = [
  '24/7 live support',
  'Avg. proxy response time ≈1 sec.',
  '155M+ proxy IP pool',
]*/

const PricingPage: React.FC = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch({ type: PricingActionsTypes.PRICING_GET_PLANS })
  }, [dispatch])

  const productsList = products.map((product, index) => (
    <div className={styles.productItem} key={`pricing-products-item_${index}`}>
      <div className={styles.productItemIconWrapper}>{product.icon}</div>

      <Text size={'l'} weight={'bold'}>
        {product.label}
      </Text>
    </div>
  ))

  /*const featuresList = features.map((feature, index) => (
    <div className={styles.featureItem} key={`feature-${index}`}>
      <div className={styles.featureIconCircle}>
        <CheckIcon className={styles.featureIcon} />
      </div>
      {feature}
    </div>
  ))*/

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h2 className={styles.title}>
          Get all SOAX products in one subscription
        </h2>

        {/*<div className={styles.productsList}>{productsList}</div>*/}

        {/*<div className={styles.featuresList}>{featuresList}</div>*/}

        <div className={styles.headerRow}>
          <div className={styles.rowBlock}>
            <div className={styles.rowBlockTitle}>
              Access <b>every SOAX product</b> from a <b>single subscription</b>
              :
            </div>

            <div className={styles.rowBlockContent}>
              <div className={styles.productsList}>{productsList}</div>
            </div>
          </div>

          <div className={styles.rowBlock}>
            <div className={styles.rowBlockTitle}>
              Spend allocation flexibly across products:
            </div>
            <div className={styles.rowBlockContent}>
              <div className={styles.spendRow}>
                <Text size={'xl'}>Example, Total spent:</Text>
                <Text size={'l'} className={styles.spendValue}>
                  $90.00
                </Text>
              </div>

              <div className={styles.spendRow}>
                <div className={`${styles.spendMarker} ${styles.blue}`} />

                <Text size={'l'}>Residential proxy:</Text>
                <Text size={'l'} className={styles.grayText}>
                  10 GB
                </Text>

                <Text size={'l'} className={styles.spendValue}>
                  $30.00
                </Text>
              </div>

              <div className={styles.spendRow}>
                <div className={`${styles.spendMarker} ${styles.orange}`} />

                <Text size={'l'}>SERP Scraper API:</Text>
                <Text size={'l'} className={styles.grayText}>
                  20K req.
                </Text>

                <Text size={'l'} className={styles.spendValue}>
                  $60.00
                </Text>
              </div>

              <div className={styles.spendRow}>
                <div className={`${styles.spendMarker} ${styles.gray}`} />

                <Text size={'l'} className={styles.grayText}>
                  Other 5 products:
                </Text>
                <Text size={'l'} className={styles.grayText}>
                  0
                </Text>

                <Text
                  size={'l'}
                  className={`${styles.spendValue} ${styles.grayText}`}
                >
                  $0.00
                </Text>
              </div>
            </div>
          </div>
        </div>
      </div>

      <PricingContent />
    </div>
  )
}

export default PricingPage
