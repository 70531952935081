import * as React from 'react'
import type { SVGProps } from 'react'
const SvgSort = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    fill="none"
    viewBox="0 0 28 28"
    {...props}
  >
    <path
      fill="currentColor"
      d="M15.715 8.785a.875.875 0 0 0 1.237 0l.84-.84v11.888a.875.875 0 1 0 1.75 0V7.946l.84.84a.875.875 0 1 0 1.237-1.238l-2.334-2.333a.875.875 0 0 0-1.237 0l-2.333 2.333a.875.875 0 0 0 0 1.237M10.208 20.054l.84-.84a.875.875 0 1 1 1.237 1.238l-2.333 2.333a.875.875 0 0 1-1.237 0L6.38 20.452a.875.875 0 1 1 1.238-1.237l.84.84V8.166a.875.875 0 1 1 1.75 0z"
    />
  </svg>
)
export default SvgSort
